import {getField, updateField} from 'vuex-map-fields';
import {PRODUCT_PAGE_TYPE} from "../constants/ProductPage";



export const getProductPageByProductID = (productID, productListings, productPages) => {
	// get the first product listing that contains this productID
	let matchProductListings = [];
	Object.values(productListings).forEach((productListing) => {
		if (productListing.productID === productID) {
			matchProductListings.push(productListing);
		}
	});

	if (matchProductListings.length === 0)
		return null;

	let productPageMatch;
	// get the product page that is associated with this product listing
	Object.values(productPages).forEach((productPage) => {
		for (const matchProductListing of matchProductListings) {
			if (productPage.productPageID === matchProductListing.productPageID && !productPage.attributes && productPage.type === PRODUCT_PAGE_TYPE.gpt) {
				productPageMatch = productPage;
			}
		}
	});

	// its possible that the gpt based match doesn't exist and in that case we need to use the user provided product page
	if (!productPageMatch) {
		Object.values(productPages).forEach((productPage) => {
			for (const matchProductListing of matchProductListings) {
				if (productPage.productPageID === matchProductListing.productPageID && !productPage.attributes) {
					productPageMatch = productPage;
				}
			}
		});
	}


	return productPageMatch;
}



const store = {
	namespaced: true,
	state: {
		productListings: {},
	},
	getters: {
		Model: (state) => state.Model,
		getField,
		getProductPageByProductID: (state, getters, rootState) => (productID) => {
			const { productPages } = rootState.productPageStore;

			return getProductPageByProductID(productID, state.productListings, productPages);
		},
	},
	mutations: {
		updateField,
		setProductListings(state, productListings) {
			productListings.forEach((productListing) => {
				state.productListings[productListing.productListingID] = productListing;
			});
		},
	},
	actions: {
		resetCriteria({ state }) {
			state.currentCriteria = newProductCriteria();
		},
	},
};

export default store;
