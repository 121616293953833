import {filter} from 'lodash';
import {request} from '../utils/Request';

const store = {
	namespaced: true,
	state: {
		payouts: {},
	},
	getters: {
		Model: (state) => state.Model,
		getPayoutsByUserID: (state) => (userID) => filter(state.payouts, { userID }),
	},
	mutations: {
		setPayouts(state, payouts) {
			payouts.forEach((payout) => {
				state.payouts[payout.payoutID] = payout;
			});
		},
	},
	actions: {
		getPayouts({ commit }, userID) {
			return new Promise((resolve, reject) => {
				request('get', 'payout', {
					userID,
				})
					.then((data) => {
						commit('setPayouts', data.payouts);

						commit('boostStore/setBoosts', data.boosts, { root: true });

						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
};

export default store;
