// NOTE: This file gets copied / transpiled to the web and app clients from the server, so you should be a bit careful with what you put in here
// A. don't put anything sensitive
// B. remember to only edit the file on the server because the client will get overwritten

export const ACTION_TYPE_LIKE = 0;
export const ACTION_TYPE_COMMENT = 1;
export const ACTION_TYPE_REPLY = 2;
export const ACTION_TYPE_MENTIONED = 3;
export const ACTION_TYPE_LOG_IN = 4;
export const ACTION_TYPE_FOLLOW = 5;
export const ACTION_TYPE_ADMIN = 6;
