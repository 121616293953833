<template>
	<div
		v-show="loading"
		class="lds-ring"
		:style="{ width: `${size}px`, height: `${size}px` }"
	>
		<div :style="[spinnerStyle, animDiv1]" />
		<div :style="[spinnerStyle, animDiv2]" />
		<div :style="[spinnerStyle, animDiv3]" />
		<div :style="[spinnerStyle]" />
	</div>
</template>

<script>
const validateDuration = (duration) => {
	const re = new RegExp(/^\d*\.?\d+(s|ms)$/);
	return re.test(duration);
}

const calcPropertyValue = (propName, originalValue, modificator) => {
	const computedStyle = {};
	const timeQuantityOuter = originalValue.match(/^\d*\.?\d+/)[0];
	const timeUnit = originalValue.match(/s|(ms)$/)[0];
	const timeQuantityInner = Math.round(timeQuantityOuter * 1000 * modificator) / 1000;

	computedStyle[propName] = timeQuantityInner + timeUnit;
	return computedStyle;
}


export default {
	name: 'RingLoader',
	props: {
		loading: {
			type: Boolean,
			default: true,
		},
		size: {
			type: Number,
			default: 80,
		},
		color: {
			type: String,
			default: '#F1C75E',
		},
		duration: {
			type: String,
			default: '1.2s',
			validator: validateDuration,
		},
	},
	data() {
		return {
			spinnerStyle: {
				borderWidth: `${this.size * 0.1}px`,
				borderColor: `${this.color} transparent transparent transparent`,
				animationDuration: this.duration,
			},
		}
	},
	computed: {
		animDiv1() {
			return calcPropertyValue('animationDelay', this.duration, -0.375)
		},
		animDiv2() {
			return calcPropertyValue('animationDelay', this.duration, -0.25)
		},
		animDiv3() {
			return calcPropertyValue('animationDelay', this.duration, -0.125)
		},
	},
}
</script>

<style scoped>
.lds-ring {
	display: inline-block;
	position: relative;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;

	/* ratio: calc(64px / 80px) */
	width: 80%;
	height: 80%;

	/* ratio: calc(8px / 80px) */
	margin: 10%;
	border: 8px solid #fff;
	border-radius: 50%;
	border-color: #fff transparent transparent transparent;
	animation-name: lds-ring;
	animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
	animation-iteration-count: infinite;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
