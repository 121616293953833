// NOTE: This file gets copied / transpiled to the web and app clients from the server, so you should be a bit careful with what you put in here
// A. don't put anything sensitive
// B. remember to only edit the file on the server because the client will get overwritten


export const PRODUCT_PAGE_TYPE = {
	user: 'user',
	gpt: 'gpt',
	admin: 'admin',
};
