import {request} from '../utils/Request';
import {S3_BASE_URL} from '../constants/Review';
import {USER_TYPE} from '../constants/User';
import {hash} from '../utils/Hash';
import router from "../router";
import {getAuth, sendEmailVerification} from "firebase/auth";
import {revvyNotify} from "../utils/revvyNotify";

const store = {
	namespaced: true,
	state: {
		sessionLoaded: false,
		loginRedirectPath: '',
		users: {},
		userID: 0,
		reCaptchaToken: '',
	},
	getters: {
		Model: (state) => state.Model,
		sessionLoaded: (state) => state.sessionLoaded,
		isLoggedIn: (state, getters) => getters.getUser() != null,
		isAdmin: (state, getters) => getters.getUser() && getters.getUser().type === USER_TYPE.admin,
		isRegularUser: (state, getters) => getters.getUser() && getters.getUser().type === USER_TYPE.regular,
		userID: (state) => state.userID,
		getUser: (state) => () => state.users[state.userID],
		getUserByID: (state) => (userID) => state.users[userID],
		username: (state, getters) => (getters.getUser() ? getters.getUser().username : ''),
		profileImage: (state, getters) => (getters.getUser() ? `${S3_BASE_URL}/${getters.getUser().profilePhotoKey}` : ''),
		getReCaptchaToken: (state) => state.reCaptchaToken,
	},
	mutations: {
		loadSessionSuccess(state, { user }) {
			if (user.emailVerified && user.phone && user.username && user.name) {
				state.users[user.userID] = user;
				state.userID = user.userID;
			}

			state.sessionLoaded = true;
		},
		loadSessionError(state) {
			state.sessionLoaded = true;
		},
		redirectAfterLogin(state) {
			let route = '/';
			if (state.loginRedirectPath) {
				route = state.loginRedirectPath;
				state.loginRedirectPath = '';
			}
			router.push(route);
		},
		setUsers(state, users) {
			users.forEach((user) => {
				state.users[user.userID] = user;
			});
		},
		setUser(state, user) {
			state.users[user.userID] = user;
		},
		setReCaptchaToken(state, token) {
			state.reCaptchaToken = token;
		},

	},
	actions: {
		checkUserByEmail({}, { email}) {
			return new Promise((resolve, reject) => {
				request('post', 'user/doesUserExist', { email }, { skipLoader: true })
					.then((exists) => {
						resolve(exists);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		checkUsername({}, { username, userID = 0, excludeSelf = false }) {
			return new Promise((resolve, reject) => {
				request('post', 'user/isUsernameTaken', { username, userID, excludeSelf }, { skipLoader: true })
					.then((isTaken) => {
						resolve(isTaken);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		getUserByUsername({ commit }, username) {
			return new Promise((resolve, reject) => {
				request('get', 'user', { username })
					.then((data) => {
						const user = data[0];
						commit('setUser', user);
						resolve(user);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		getUser({ commit }, userID) {
			if (!userID)
				return null;

			return new Promise((resolve, reject) => {
				request('get', `user/${hash(userID)}`)
					.then((data) => {
						commit('setUser', data);
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		deleteUser({ commit, dispatch }, userID) {
			return new Promise((resolve, reject) => {
				request('delete', `user?userID=${userID}`, {}, { sendToken: true })
					.then((res) => {
						const { status } = res;

						if (status) {
							dispatch('logout');
							window.location.href = '/';
						}

						resolve();
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		updateUser({ commit }, user) {
			return new Promise((resolve, reject) => {
				request('put', 'user', user)
					.then((data) => {
						commit('setUser', data);
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		getSession({ commit }) {
			return new Promise((resolve, reject) => {
				request('get', 'user/session')
					.then((data) => {
						commit('loadSessionSuccess', data);
						resolve(data);
					})
					.catch((error) => {
						commit('loadSessionError', error);
						reject(error);
					});
			});
		},
		updateSession({ commit }, user) {
			return new Promise((resolve, reject) => {
				request('put', 'user/session', user)
					.then((data) => {
						commit('setUser', data);
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		login({ commit, state }, { required = true, redirectURL = '', appState = {} } = {}) {
			if (!redirectURL)
				redirectURL = router.currentRoute.value.path;

			state.loginRedirectPath = redirectURL;
			const loginRoute = required ? '/login/required' : '/login';
			router.push(loginRoute);
		},
		onFirebaseAuthenticated({ commit, dispatch }, {
			token, name, username, birthday, externalReferralCode, recaptchaToken, shadowerEmail
		}) {
			return new Promise(async (resolve, reject) => {
				request('post', 'user/onFirebaseAuthenticated', {
					username,
					name,
					birthday,
					externalReferralCode,
					recaptchaToken,
					shadowerEmail,
				}, { sendToken: true })
					.then((user) => {
						resolve(user);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		pushToNextAuthStep({ state, commit }, user) {
			if (user.emailVerified && user.phone && user.username && user.name) {
				state.userID = user.userID;
				state.users[user.userID] = user;
			}

			// check to see if the user has a verified phone number.
			// if not, redirect to phone verification page
			if (!user.emailVerified) {
				const user = getAuth().currentUser;
				sendEmailVerification(user);

				router.push('/check-email');
			} else if (!user.phone)
				router.push('/verify');
			else if (!user.name || !user.username || !user.birthday || user.birthday === '0000-00-00')
				router.push('/finish-signup');
			else {
				commit('userStore/redirectAfterLogin', {}, { root: true });

				revvyNotify({
					type: "success",
					text: "You have successfully logged in!",
				});
			}
		},
		logout({ state, commit, dispatch }) {
			return new Promise((resolve, reject) => {
				request('post', 'user/logout')
					.then((data) => {
						dispatch('onLogoutSuccess');
						resolve(data);
					})
					.catch((error) => {
						// dispatch('onLogoutFailed');
						reject(error);
					});
			});
		},
		onLogoutSuccess({ state }) {
			const userID = state.userID;
			delete state.users[userID];
			state.userID = 0;
		},
		sendVerification({ commit }, data) {
			return new Promise((resolve, reject) => {
				request('post', 'user/sendVerification', data)
					.then((data) => {
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		confirmVerification({ commit, getters, state }, data) {
			return new Promise((resolve, reject) => {
				request('post', 'user/confirmVerification', data)
					.then((res) => {
						resolve(res.data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		getBlockedUser({ commit }, data) {
			return new Promise((resolve, reject) => {
				request('get', 'user/block')
			});
		},
		signUpForBeta({}, email) {
			return new Promise((resolve, reject) => {
				request('post', 'user/signUpForBeta', { email })
					.then((data) => {
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		connectStripe() {
			return new Promise((resolve, reject) => {
				request('get', 'user/getStripeOnboardingLink')
					.then((data) => {
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		payOutToStripe({}, payout) {
			return new Promise((resolve, reject) => {
				request('post', 'user/payOutToStripe', {userID: payout.userID, payoutID: payout.payoutID})
					.then((data) => {
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		addToTestBalance() {
			return new Promise((resolve, reject) => {
				request('post', 'user/addToTestBalance')
					.then((data) => {
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
};

export default store;
