import {createStore} from 'vuex';

import CommentStore from './CommentStore';
import ProductStore from './ProductStore';
import GeneralStore from './GeneralStore';
import UserStore from './UserStore';
import BrandStore from './BrandStore';
import ReviewStore from './ReviewStore';
import VoteStore from './VoteStore';
import FollowStore from './FollowStore';
import ViewStore from './ViewStore';
import NotificationActionStore from './NotificationActionStore';
import PayoutStore from './PayoutStore';
import BoostStore from './BoostStore';
import ProductPageStore from './ProductPageStore';
import ProductListingStore from './ProductListingStore';
import BlockUserStore from "./BlockUserStore";

export default createStore({
	modules: {
		generalStore: GeneralStore,
		userStore: UserStore,
		productStore: ProductStore,
		productPageStore: ProductPageStore,
		brandStore: BrandStore,
		reviewStore: ReviewStore,
		commentStore: CommentStore,
		followStore: FollowStore,
		voteStore: VoteStore,
		viewStore: ViewStore,
		notificationActionStore: NotificationActionStore,
		payoutStore: PayoutStore,
		boostStore: BoostStore,
		productListingStore: ProductListingStore,
		blockUserStore: BlockUserStore,
	},
});
