import {find, filter} from 'lodash';
import {getField, updateField} from 'vuex-map-fields';
import {request} from '../utils/Request';

const incrementUserFollows = ({
	commit, rootState, followersUserID, followingUserID, increment,
}) => {
	const { userStore } = rootState;
	const followersUser = userStore.users[followersUserID];
	const followingUser = userStore.users[followingUserID];

	if (followersUser) {
		followersUser.numFollowing += increment;
		commit('userStore/setUser', followersUser, { root: true });
	}

	if (followingUser) {
		followingUser.numFollowers += increment;
		commit('userStore/setUser', followingUser, { root: true });
	}
};

const store = {
	namespaced: true,
	state: {
		// dictionary of follow objects modeled after the Follow DB
		follows: {},
	},
	getters: {
		Model: (state) => state.Model,
		getField,
		getFollowersByUserID: (state) => (userID) => filter(state.follows, { followingUserID: userID }),
		getFollowingsByUserID: (state) => (userID) => filter(state.follows, { followersUserID: userID }),
		getFollowByIDs: (state) => ({ followersUserID, followingUserID }) => filter(state.follows, { followingUserID, followersUserID })[0],
		isFollowing: (state) => (userID) => !!find(state.follows, { followingUserID: userID }),
	},
	mutations: {
		updateField,
		setFollows(state, follows) {
			follows.forEach((follow) => {
				state.follows[follow.followID] = follow;
			});
		},
		createFollow(state, follow) {
			state.follows[follow.followID] = follow;
		},
		removeFollow(state, followID) {
			delete state.follows[followID];
		},
	},
	actions: {
		getFollowers({ commit }, userID) {
			return new Promise((resolve, reject) => {
				request('get', 'follow', {
					followingUserID: userID,
					includeCount: true,
				})
					.then((data) => {
						commit('setFollows', data.rows);
						commit('userStore/setUsers', data.users, { root: true });
						resolve(data);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
		getFollowing({ commit }, userID) {
			return new Promise((resolve, reject) => {
				request('get', 'follow', {
					followersUserID: userID,
					includeCount: true,
				})
					.then((data) => {
						commit('setFollows', data.rows);
						commit('userStore/setUsers', data.users, { root: true });
						resolve(data);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
		getFollow({ commit }, { followersUserID, followingUserID }) {
			return new Promise((resolve, reject) => {
				request('get', 'follow', { followersUserID, followingUserID })
					.then((data) => {
						const follow = data[0];
						if (follow)
							commit('setFollows', [follow]);
						resolve(data[0]);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
		createFollow({ commit, rootState }, { followersUserID, followingUserID }) {
			return new Promise((resolve, reject) => {
				request('post', 'follow', { followersUserID, followingUserID })
					.then((follow) => {
						commit('createFollow', follow);

						incrementUserFollows({
							commit,
							rootState,
							followersUserID,
							followingUserID,
							increment: 1,
						});

						resolve(follow);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
		removeFollow({ commit, getters, rootState }, { followersUserID, followingUserID }) {
			const follow = getters.getFollowByIDs({
				followersUserID,
				followingUserID,
			});

			return new Promise((resolve, reject) => {
				request('delete', 'follow', { followersUserID, followingUserID })
					.then(() => {
						commit('removeFollow', follow.followID);

						incrementUserFollows({
							commit,
							rootState,
							followersUserID,
							followingUserID,
							increment: -1,
						});

						resolve(true);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
	},
};

export default store;
