import {notify} from "@kyvg/vue3-notification";

const ADDITIONAL_DURATION_PER_CHAR = 50;

export const revvyNotify = (options) => {
	const { type, text, title, duration = 4000 } = options;

	const additionalDuration = (title ? title.length * ADDITIONAL_DURATION_PER_CHAR : 0) + (text ? text.length * ADDITIONAL_DURATION_PER_CHAR : 0);

	notify({
		type,
		title,
		text,
		duration: duration + additionalDuration,
	});
}
