<script setup>
import ProfilePhoto from "./ProfilePhoto";
import {map, cloneDeep} from "lodash";
import { required, helpers } from "@vuelidate/validators";
import VuelidateError from "./VuelidateError";

import {
	featherX,
	featherUploadCloud,
	faSolidStar,
	faRegularBuilding, featherPackage, featherHelpCircle,
} from "../utils/svgs";
import RevvyModal from "./RevvyModal.vue";
import BarRatingSelect from "./BarRatingSelect.vue";
import EllipsisLoader from "./EllipsisLoader.vue";
import uploadShell from "../assets/upload-shell.png";

import store from "../stores";
import router from "../router";
import {revvyNotify} from "../utils/revvyNotify";
import debounce from "lodash/debounce";
import {newProductPageCriteria} from "../stores/ProductPageStore";
import axios from "axios";
import {computed, defineProps, onMounted, onUnmounted, reactive, ref} from "vue";
import {useVuelidate} from "@vuelidate/core";
import {MAX_DESCRIPTION_LENGTH, MAX_HEADER_LENGTH, MAX_BRAND_NAME_LENGTH, MAX_PRODUCT_NAME_LENGTH, MAX_MODEL_NUMBER_LENGTH, S3_BASE_URL, MAX_IMAGE_DIMENSION} from "../constants/Review";
import {PLACEHOLDERS} from "../constants/RotatingPlaceholders";
import {MAX_VIDEO_SIZE_MB, MAX_VIDEO_LENGTH_SEC} from "../constants/Media";
import RingLoader from "./RingLoader.vue";
import {CREATOR_PROGRAM_STATUS} from "../constants/User";


function Deselect(_this, type) {
	return {
		render: (createElement) => {
			return createElement("span", {
				on: {
					click: () => {
						if (type === "brand") _this.brand = { name: "" };
						else _this.product = { name: "" };

						_this.$emit("deselect", _this);
					},
				},
				class: "w-4 h-4 block [&>svg]:w-4 [&>svg]:h-4",
				domProps: {
					innerHTML: featherX,
				},
			});
		},
	};
}

const getRandomIndex = (arrayLength) => Math.floor(Math.random() * arrayLength);
const randomIndex = getRandomIndex(PLACEHOLDERS.length);

let placeholderIntervalId;

const getBrandByID = store.getters['brandStore/getBrandByID'];
const getProductPageByProductID = store.getters['productListingStore/getProductPageByProductID'];

const userID = computed(() => store.getters['userStore/userID']);
const user = computed(() => store.getters['userStore/getUser']());
const isLoggedIn = computed(() => store.getters['userStore/isLoggedIn']);
const username = computed(() => store.getters['userStore/username']);
const visibleDropdown = computed(() => store.getters['generalStore/visibleDropdown']);


const props = defineProps({
	name: { type: String, default: 'edit-review-modal' },
	initialReview: { type: Object, required: false },
	size: 'lg',
});


const imgRef = ref(null);
const fileRef = ref(null);
const isTooltipVisible = ref({ brand: false, product: false });

const brandDown = ref(false);
const productDown = ref(false);

const dataObj = {
	isPosting: false,
	products: [],
	product: {
		name: '',
	},
	brands: [],
	brand: {
		name: '',
	},
	review: null,
	videoUploadPercent: 0,
	videoProcessingPercent: 0,
	isVideoUploading: false,
	hasAffiliateLink: false,
	DeselectBrand: Deselect(this, 'brand'),
	DeselectProduct: Deselect(this, 'product'),
	files: [],
	isDragging: false,
	fileName: null,
	fileSize: null,
	isLoading: false,
	maxLoadRetries: 20,
	currentLoadTry: 0,
	imgSrc: null,
	reviewTitle: null,
	reviewDescription: null,
	isHeightGreaterThanWidth: false,
	brandNameSearchResults: [],
	placeholders: {
		brand: PLACEHOLDERS[randomIndex].brand,
		product: PLACEHOLDERS[randomIndex].product,
	},
	rotatePlaceholder: true,
	randomPlaceholderIndex: null
};

const data = reactive(dataObj);

const mediaHandles = computed(() => {
	if (data.review) {
		return [
			...map(data.review.videoKeys, (handle) => ({
				type: 'video',
				mediaHandle: handle,
			})),
			...map(data.review.imageKeys, (handle) => ({
				type: 'image',
				mediaHandle: handle,
			})),
		];
	}

	return [];
});


const isExisting = computed(() => {
	return data.review && data.review.reviewID > 0;
});

const modalTitle = computed(() => {
	return isExisting.value ? 'Edit review' : 'Create a review';
});

const brandName = computed(() => {
	return data.brand ? data.brand.name : '';
});

const productName = computed(() => {
	return data.product ? data.product.name : '';
});

const modelNumber = computed(() => {
	return data.product ? data.product.modelNumber : '';
});

const numMedia = computed(() => {
	return mediaHandles.value.length;
});

const reviewHeader = computed(() => {
	return data.review ? data.review.header : '';
});

const validations = {
	productName: {
		required: helpers.withMessage('Product name is required', required),
		$lazy: true,
	},
	brandName: {
		required: helpers.withMessage('Brand name is required', required),
		$lazy: true,
	},
	reviewHeader: {
		required: helpers.withMessage('Review header is required', required),
		$lazy: true,
	},
	mediaHandles: {
		hasMedia: helpers.withMessage(
			'Please add a video of the product',
			() => numMedia.value > 0
		),
	},
};

const v$ = useVuelidate(validations, {
	mediaHandles,
	brandName,
	productName,
	reviewHeader,
});

onMounted(() => {
	data.review = cloneDeep(props.initialReview);
	if (data.review && data.review.brandID > 0)
		data.brand = getBrandByID(data.review.brandID);
	if (data.review && data.review.productID > 0)
		data.product = getProductPageByProductID(data.review.productID);


	if (data.review && data.review.mediaKeys) {
		setIsImageGreaterThanHeight(data.review.mediaKeys[0].replace('video-', ''));
	}
	placeholderIntervalId = setInterval(updatePlaceholders, 3000);
});

onUnmounted(() => {
	clearInterval(placeholderIntervalId);
})

const onMoreInfoClick = () => {
	isTooltipVisible.value['product'] = false;
	store.commit('generalStore/closeModal', props.name);
	router.push('/newsroom/how-to-name-your-product');
}

const onRatingChange = (rating) => {
	data.review.rating = rating;
}

const resetModalData = () => {
	data.review = cloneDeep(props.initialReview)
	data.brand = { name: '' };
	data.product = { name: '' };
	data.productPage = { name: '' };
	data.fileName = '';
	data.fileSize = 0;
	data.isLoading = false;
	data.files = [];

	v$.value.$reset();
}

const close = () => {
	store.commit('generalStore/closeModal', props.name);
	if (!isExisting.value) {
		router.push('/');
	}
}

const onFileRemove = (type, mediaHandle) => {
	let i;
	if (type === 'image') {
		i = data.review.imageKeys.indexOf(mediaHandle);
		data.review.imageKeys.splice(i, 1);
		i = data.review.mediaKeys.indexOf(`image-${mediaHandle}`);
		data.review.mediaKeys.splice(i, 1);
	} else if (type === 'video') {
		i = data.review.videoKeys.indexOf(mediaHandle);
		data.review.videoKeys.splice(i, 1);
		i = data.review.mediaKeys.indexOf(`video-${mediaHandle}`);
		data.review.mediaKeys.splice(i, 1);
	}
	data.files = [];
}

const onPostReview = async () => {
	if (!isLoggedIn.value) return store.dispatch('userStore/login');

	const isValid = await v$.value.$validate();
	if (!isValid) return false;

	if (data.isPosting) return;
	data.isPosting = true;

	if (!isExisting.value) {
		store
			.dispatch('reviewStore/createReview', {
				review: data.review,
				newBrand: data.brand,
				newProduct: data.product,
			})
			.then(() => {
				close();
				revvyNotify({
					type: 'success',
					text: 'Review Created',
				});
				resetModalData();
			})
			.catch((e) => {
				revvyNotify({
					type: 'error',
					text: e.message,
				});
			})
			.finally(() => {
				data.isPosting = false;
			});
	} else {
		store
			.dispatch('reviewStore/updateReview', {
				review: data.review,
				newBrand: !data.brand.brandID ? data.brand : null,
				newProduct: !data.product.productID ? data.product : null,
			})
			.then(() => {
				close();
				revvyNotify({
					type: 'success',
					text: 'Review Updated',
				});
				resetModalData();
			})
			.catch((e) => {
				revvyNotify({
					type: 'error',
					text: e.message,
				});
			})
			.finally(() => {
				data.isPosting = false;
			});
	}
}


const onProductNameSearch = (e) => {
	let keywords = e.target.value;

	if (data.brand.name.length) keywords = keywords.replace(data.brand.name.trim() + ' ', '');

	if (e.target.value !== keywords) {
		revvyNotify({
			type: 'warning',
			text: 'Please do not include the brand name in the product name field. Your goal should be to input the exact product name assigned by this brand.',
		})
	}

	data.product = {
		...data.product,
		name: keywords,
	};

	let brandID;
	if (data.review.brandID) brandID = data.review.brandID;

	if (keywords.length) {
		searchProducts(keywords, brandID);
	}
}
const searchProducts = debounce((keywords, brandID) => {
	store
		.dispatch(
			'productPageStore/searchProductPages',
			newProductPageCriteria({ keywords, brandID }, { skipLoader: true }),
			{ root: true }
		)
		.then((res) => {
			data.products = res.rows;
		});
}, 350);

const onProductSelect = (product) => {
	data.product = {
		...data.product,
		name: product.name,
	};

	if (product.productID > 0) {
		data.review.brandID = product.brandID;
		data.review.productID = product.productID;
	} else {
		data.review.productID = 0;
	}
}

const onBrandNameSearch = (e) => {
	const keywords = e.target.value;
	data.brand = {
		...data.brand,
		name: keywords,
	};

	if (keywords.length) {
		searchBrands(keywords);
	}
}

// NOTE: debounce doesn't work with arrow function
const searchBrands = debounce(function (keywords) {
	// the component pointer of this needs to be passed in via the
	// vm parameter because _debouce affects the scope
	store
		.dispatch('brandStore/searchBrands', {
			keywords,
			settings: { skipLoader: true },
		})
		.then((brands) => {
			data.brands = brands;
		});
}, 350);

const onBrandSelect = (brand) => {
	data.brand = brand;

	if (brand.brandID > 0) data.review.brandID = brand.brandID;
	else data.review.brandID = 0;
}

const onFocus = (input) => {
	if (input === 'brandName' || input === 'productName') {
		data.rotatePlaceholder = false;
		data.placeholders.brand = '';
		data.placeholders.product = '';
		clearInterval(placeholderIntervalId);
	}
}

const onBlur = () => {
	if (!brandDown.value && !productDown.value) {
		store.commit('generalStore/setVisibleDropdown', '');

		if (data.brand.name.length || data.product.name.length) return;

		data.rotatePlaceholder = true;
		data.randomPlaceholderIndex = getRandomIndex(PLACEHOLDERS.length);
		data.placeholders.brand = PLACEHOLDERS[data.randomPlaceholderIndex].brand;
		data.placeholders.product = PLACEHOLDERS[data.randomPlaceholderIndex].product;
		placeholderIntervalId = setInterval(updatePlaceholders, 3000);
	}
}

const onModelNumberChange = (e) => {
	const modelNumber = e.target.value;
	data.product = {
		...data.product,
		modelNumber,
	};
}

const onFileDrop = (e) => {
	data.isLoading = true;
	fileRef.value.files = e.dataTransfer.files;
	onFileSelected();
	data.isDragging = false;
}

const onDragOver = () => {
	data.isDragging = true;
}

const onDragLeave = () => {
	data.isDragging = false;
}

const onFileSelected = (e) => {
	data.isLoading = true;
	data.files = [...fileRef.value.files];
	const file = data.files[0];

	data.fileName = data.files[0].name;
	data.fileSize = `${(data.files[0].size / 1000 / 1000).toFixed(1)}`; // MB with one decimal

	resizeFile(file).then((resizedFile) => {
		const formData = new FormData();
		formData.append('file', resizedFile);

		data.isVideoUploading = true;

		axios
			.post(`${process.env.VUE_APP_API_URL}/upload-file`, formData, {
				// This is firing all at once, perhaps an issue with Chrome?
				onUploadProgress: (progressEvent) => {
					const { loaded, total } = progressEvent;

					data.videoUploadPercent = Math.round((loaded / total) * 100);
				},
			})
			.then(async (uploadRes) => {
				data.isVideoUploading = false;

				const checkProgress = () => {
					axios.get(`${process.env.VUE_APP_API_URL}/video-progress?key=${uploadRes.data.key}`)
						.then((res) => {
							if (!res.data.progress) {
								data.videoProcessingPercent = 0;
								setTimeout(checkProgress, 1000);
							} else if (Math.round(res.data.progress * 100) < 100) {
								data.videoProcessingPercent = Math.round(res.data.progress * 100);
								setTimeout(checkProgress, 1000);
							} else {
								data.videoProcessingPercent = 100;

								data.isLoading = false;

								if (!data.review.mediaKeys) data.review.mediaKeys = [];
								if (!data.review.imageKeys) data.review.imageKeys = [];
								if (!data.review.videoKeys) data.review.videoKeys = [];
								const fileType = uploadRes.data.mimetype.split('/')[0];
								const fileKey = uploadRes.data.key;
								if (fileType === 'image') {
									data.review.imageKeys.push(fileKey);
									data.review.mediaKeys.push(`image-${fileKey}`);
								} else if (fileType === 'video') {
									data.review.videoKeys.push(fileKey);
									data.review.mediaKeys.push(`video-${fileKey}`);
								}

								setIsImageGreaterThanHeight(fileKey);
							}
						});
				}

				checkProgress();
			});
	});
}

const resizeFile = (file) => {
	return new Promise((resolve) => {
		// if it's an image, size it down so it's not super huge
		if (file.type.match(/image.*/)) {
			// Load the image
			const reader = new FileReader();
			reader.onload = (readerEvent) => {
				const originalImageDataURL = readerEvent.target.result;
				const image = new Image();
				image.onload = () => {
					// Resize the image
					const canvas = document.createElement('canvas');
					let { width } = image;
					let { height } = image;

					if (width > height) {
						if (width > MAX_IMAGE_DIMENSION) {
							height *= MAX_IMAGE_DIMENSION / width;
							width = MAX_IMAGE_DIMENSION;
						}
					} else if (height > MAX_IMAGE_DIMENSION) {
						width *= MAX_IMAGE_DIMENSION / height;
						height = MAX_IMAGE_DIMENSION;
					}
					canvas.width = width;
					canvas.height = height;
					canvas.getContext("2d").drawImage(image, 0, 0, width, height);
					const dataUrl = canvas.toDataURL("image/jpeg");
					const resizedImage = dataURLToBlob(dataUrl);
					resolve(resizedImage);
				};
				image.src = originalImageDataURL;
			};
			reader.readAsDataURL(file);
		} else {
			// if it's a video, nothing we can do for now
			// I tried the wasm ffmpeg npm package but ran into issues with it - maybe a future version will work - I tried v0.11.0 of @ffmpeg/core
			const sizeInMb = file.size / 10 ** 6;

			if (sizeInMb > MAX_VIDEO_SIZE_MB) {
				revvyNotify({
					text: `File must be less than ${MAX_VIDEO_SIZE_MB}MB`,
					type: "warn",
				});
			} else {
				resolve(file);
			}
		}
	});
}

const dataURLToBlob = (dataURL) => {
	const BASE64_MARKER = ";base64,";
	if (dataURL.indexOf(BASE64_MARKER) === -1) {
		const parts = dataURL.split(",");
		const contentType = parts[0].split(":")[1];
		const raw = parts[1];

		return new Blob([raw], { type: contentType });
	}

	const parts = dataURL.split(BASE64_MARKER);
	const contentType = parts[0].split(":")[1];
	const raw = window.atob(parts[1]);
	const rawLength = raw.length;

	const uInt8Array = new Uint8Array(rawLength);

	for (let i = 0; i < rawLength; ++i) uInt8Array[i] = raw.charCodeAt(i);

	return new Blob([uInt8Array], { type: contentType });
}

const tryLoading = (mediaHandle) => {
	if (imgRef.value && imgRef.value[0])
		imgRef.value[0].src = `${S3_BASE_URL}/${mediaHandle}_md`;
}

const handleError = (mediaHandle) => {
	setTimeout(() => {
		tryLoading(mediaHandle);
	}, 1250);
}

const handleLoad = () => {
	data.isLoading = false;
}

const setIsImageGreaterThanHeight = (handle) => {
	const img = new Image();
	img.onload = () => {
		const height = img.height;
		const width = img.width;

		if (height > width) {
			data.isHeightGreaterThanWidth = true;
		}
	};
	img.src = `${S3_BASE_URL}/${handle}_sm`;
}

const onBrandNameClick = (e) => {
	e.stopPropagation();
	store.commit("generalStore/setVisibleDropdown", "brand-searchbar");
};

const onProductNameClick = (e) => {
	e.stopPropagation();
	store.commit("generalStore/setVisibleDropdown", "product-searchbar");
};

const updatePlaceholders = () => {
	if (!data.rotatePlaceholder) return;

	data.randomPlaceholderIndex = getRandomIndex(PLACEHOLDERS.length);
	data.placeholders.brand = PLACEHOLDERS[data.randomPlaceholderIndex].brand;
	data.placeholders.product = PLACEHOLDERS[data.randomPlaceholderIndex].product;
};
</script>


<template>
	<revvy-modal
		:name="name"
		:title="modalTitle"
		size="lg"
	>
		<div class="modal-max-height w-full">
			<form
				v-if="data.review"
				class="edit-review-form w-full"
				:class="{ new: !isExisting }"
			>
				<div class="grid grid-cols-[1fr] sm:grid-cols-[260px_1fr] gap-14">
					<div class="h-[530px] w-[249px]">
						<div class="relative">
							<div>
								<div
									v-if="data.isLoading"
									class="flex items-center p-6 justify-center flex-col w-[249px] h-[530px] border-gray-500 rounded-[36px] border-2 border-dashed"
								>
									<p class="text-sm text-center">
										{{ data.fileName }}
									</p>
									<ellipsis-loader />
									<div v-if="data.isVideoUploading">
										<div class="text-base text-center">
											{{ data.videoUploadPercent }}%
										</div>
										<div class="text-sm">
											uploading
										</div>
									</div>
									<div v-else>
										<div class="text-base text-center">
											{{ data.videoProcessingPercent }}%
										</div>
										<div class="text-sm">
											processing
										</div>
									</div>
								</div>
								<div
									v-for="({ type, mediaHandle }, idx) in mediaHandles"
									:key="idx"
									class="flex flex-col items-center"
								>
									<div
										:class="[data.isLoading ? 'hidden' : 'flex']"
										class="items-center w-[249px] h-[530px] p-1 bg-black rounded-[36px] relative"
									>
										<div
											class="absolute z-30 top-[50px] left-1/2 transform -translate-x-1/2
										grid grid-rows-2 gap-2 text-center w-10/12 mx-auto"
										>
											<p class="font-bold text-sm text-white">
												{{ data.product.name }}
											</p>
											<p class="font-bold text-sm text-primary">
												{{ data.brand.name }}
											</p>
										</div>
										<div
											class="absolute z-30 bottom-[83px] left-1/2 transform -translate-x-1/2 w-10/12 mx-auto"
										>
											<div class="flex items-center mb-2">
												<profile-photo
													class="inline mr-2"
													:user-i-d="userID"
													:width="24"
													:link-to-profile="false"
												/>
												<span class="text-xs text-white">{{ username }} </span>
											</div>
											<p class="font-bold text-[12px] text-white leading-4 mb-1">
												{{ data.review.header }}
											</p>
											<p
												class="text-[9px] text-white leading-3 w-10/12 text-ellipsis overflow-hidden description"
											>
												{{ data.review.description }}
											</p>
											<div class="flex justify-between items-center w-10/12">
												<div class="flex items-center">
													<div class="relative w-16 h-2 mt-1">
														<div class="absolute w-[60px] rounded-full bg-gray-800 h-1 l-0 b-0 z-10" />
														<div
															class="absolute rounded-full bg-primary h-1 l-0 b-0 z-20"
															:style="{width: data.review.rating * 12 + 'px'}"
														/>
													</div>
													<p class="text-[10px] text-primary">
														{{ Number(data.review.rating).toFixed(1) }}
													</p>
													<span
														class="svg-icon w-2 h-2 fill-primary ml-1"
														v-html="faSolidStar"
													/>
												</div>

												<p class="text-[9px] text-gray-500">
													1 minute ago
												</p>
											</div>
										</div>
										<img
											:src="uploadShell"
											alt="Uploaded video to app"
											class="absolute top-0 left-0 z-20"
										>
										<img
											v-if="type === 'image'"
											alt="uploaded"
											:src="`${S3_BASE_URL}/${mediaHandle}`"
											class="object-contain"
										>
										<img
											v-if="type === 'video'"
											ref="imgRef"
											alt="uploaded"
											:src="`${S3_BASE_URL}/${mediaHandle}_sm`"
											class="w-full rounded-[36px] z-10 h-[530px] pb-[74px]"
											:class="[data.isHeightGreaterThanWidth ? 'object-cover' : 'object-contain']"
											@error="handleError(mediaHandle)"
											@load="handleLoad"
										>
									</div>

									<div
										v-if="!isExisting"
										class="mt-4 flex w-full border border-gray-200 px-4 py-2 rounded-lg justify-between items-center"
									>
										<div class="flex items-center">
											<p class="block text-gray-900 text-xs overflow-hidden max-w-[15ch] truncate">
												{{ data.fileName }}
											</p>
											<p
												id="file-size"
												class="text-xs ml-2 text-gray-400 whitespace-nowrap"
											>
												{{ data.fileSize }} MB
											</p>
										</div>
										<button
											type="button"
											class="ml-2 text-xs text-gray-900 font-medium"
											@click="onFileRemove(type, mediaHandle)"
										>
											{{ data.isLoading ? 'Cancel' : 'Remove' }}
										</button>
									</div>
									<div
										v-else
										class="mt-4 w-full py-2 px-4"
									></div>
								</div>

								<div
									v-if="data.files.length === 0 && !isExisting"
									class="flex items-center justify-center relative border-dashed border-2 w-[249px] h-[530px] bg-white  hover:border-gray-500 rounded-[36px]"
									:class="[data.isDragging ? 'border-gray-500' : 'border-gray-200']"
									@drop.prevent="onFileDrop"
									@dragover.prevent="onDragOver"
									@dragleave.prevent="onDragLeave"
								>
									<input
										id="video-upload"
										ref="fileRef"
										type="file"
										accept="video/*"
										style="display: none"
										@change="onFileSelected"
									>
									<label
										for="video-upload"
										class="h-full rounded-lg cursor-pointer w-full p-4 text-center flex flex-col items-center justify-center"
									>
										<p
											v-if="data.isDragging"
											class="font-normal pointer-events-none"
										>Drop video here</p>
										<div
											v-else
											class="flex flex-col items-center"
										>
											<span
												class="text-gray-300 rounded-sm p-4 svg-icon w-16 h-16"
												v-html="featherUploadCloud"
											/>
											<p class="font-medium m-1">Drag and drop a video file</p>
											<p class="m-1">or</p>
											<button
												v-if="!data.review.mediaKeys || data.review.mediaKeys.length === 0"
												type="button"
												class="m-1 btn btn-primary"
												@click="fileRef.click()"
											>
												Browse
											</button>
											<div class="mt-4 text-sm text-gray-500">
												<p class="mt-2">{{ MAX_VIDEO_SIZE_MB }} MB limit</p>
												<p class="mt-2">Up to {{ MAX_VIDEO_LENGTH_SEC }} seconds</p>
											</div>
										</div>
									</label>
								</div>
							</div>
							<vuelidate-error
								:v$="v$"
								name="mediaHandles"
							/>
						</div>
					</div>
					<div>
						<div
							v-if="!isExisting"
							class="product-name-group mb-8"
						>
							<div class="mb-2 font-medium flex items-center">
								<span>Who makes this product?</span>

								<div class="relative">
									<span
										class="svg-icon w-4 h-4 ml-2 text-gray-500"
										@mouseover="isTooltipVisible['brand'] = true"
										@mouseleave="isTooltipVisible['brand'] = false"
										v-html="featherHelpCircle"
									/>
									<div
										v-if="isTooltipVisible['brand']"
										class="absolute top-0 left-8 z-10 w-[190px] p-3 bg-white rounded-lg shadow-lg text-xs text-gray-700 font-normal"
									>
										<p>
											Select the brand or company that makes this product e.g. Apple, Toyota, Samsung etc.
										</p>
									</div>
								</div>
							</div>

							<div class="relative rotating">
								<input
									class="border border-gray-200 rotating"
									spellcheck="false"
									:maxlength="MAX_BRAND_NAME_LENGTH"
									:value="brandName"
									@input="onBrandNameSearch"
									@blur="onBlur()"
									@focus="onFocus('brandName')"
									@click="onBrandNameClick"
								/>
								<p
									class="text-gray-400 text-sm absolute right-0 mt-1"
									:class="[
										data.brand.name && data.brand.name.length === MAX_BRAND_NAME_LENGTH
											? 'text-red-400'
											: 'text-gray-400',
									]"
								>
									{{ data.brand.name ? data.brand.name.length : 0 }}/{{ MAX_BRAND_NAME_LENGTH }}
								</p>
								<transition
									name="fade"
									mode="out-in"
								>
									<span
										:key="data.placeholders.brand"
										class="text-gray-400 placeholder"
									>
										{{ data.placeholders.brand }}
									</span>
								</transition>

								<div
									v-if="data.brands.length && visibleDropdown === 'brand-searchbar'"
									class="absolute left-0 right-0 top-[48px] rounded-lg bg-white shadow-md py-2 z-10"
								>
									<button
										v-for="brand in data.brands.slice(0, 6)"
										:key="brand.brandID"
										type="button"
										class="flex items-center px-4 py-2 text-sm hover:bg-gray-50 w-full"
										@click="() => onBrandSelect(brand)"
										@mousedown="() => brandDown = true"
										@mouseup="() => brandDown = false"
									>
										<span
											class="svg-icon mr-4 h-8 w-8 rounded-full bg-gray-100 p-2 text-gray-600"
											v-html="faRegularBuilding"
										/>

										<span class="flex items-baseline">
											<span class="line-clamp-1 text-left">{{ brand.name }}</span>
											<span class="ml-1 text-xs">
												({{ brand.numPages }})
											</span>
										</span>
									</button>
								</div>
							</div>


							<vuelidate-error
								:v$="v$"
								name="brandName"
							/>
						</div>

						<div
							v-if="!isExisting"
							class="mb-8"
						>
							<div class="mb-2 font-medium flex items-center">
								<span>Product name</span>

								<div
									class="relative"
									@mouseover="isTooltipVisible['product'] = true"
									@mouseleave="isTooltipVisible['product'] = false"
								>
									<span
										class="svg-icon w-4 h-4 ml-2 text-gray-500 cursor-pointer"
										@click="onMoreInfoClick"
										v-html="featherHelpCircle"
									/>
									<div
										v-if="isTooltipVisible['product']"
										class="absolute top-0 left-0 ml-8 z-10 w-[220px] p-3 bg-white rounded-lg shadow-lg text-xs font-normal"
									>
										<p class="mb-2">
											This should be the manufacturer's assigned product name.
										</p>
										<p class="mb-2">
											<span class="font-semibold">Do</span> include the year in the product name e.g. 2024 Camry, AirPods (2nd generation).
										</p>
										<p class="">
											<span class="font-semibold">Don't</span> use the brand name in this field like Apple iPhone 12 or Toyota Camry 2024.
										</p>
									</div>
								</div>
							</div>

							<div class="relative rotating">
								<input
									class="border border-gray-200 rotating"
									spellcheck="false"
									:maxlength="MAX_PRODUCT_NAME_LENGTH"
									:value="productName"
									@input="onProductNameSearch"
									@blur="onBlur()"
									@focus="onFocus('productName')"
									@click="onProductNameClick"
								/>
								<p
									class="text-gray-400 text-sm absolute right-0 mt-1"
									:class="[
										data.product.name && data.product.name.length === MAX_PRODUCT_NAME_LENGTH
											? 'text-red-400'
											: 'text-gray-400',
									]"
								>
									{{ data.product.name ? data.product.name.length : 0 }}/{{ MAX_PRODUCT_NAME_LENGTH }}
								</p>
								<transition
									name="fade"
									mode="out-in"
								>
									<span
										:key="data.placeholders.product"
										class="text-gray-400 placeholder"
									>
										{{ data.placeholders.product }}
									</span>
								</transition>
								<div
									v-if="data.products.length && visibleDropdown === 'product-searchbar'"
									class="absolute left-0 right-0 top-[48px] rounded-lg bg-white shadow-md py-2 z-10"
								>
									<button
										v-for="product in data.products.slice(0, 6)"
										:key="product.brandID"
										type="button"
										class="flex items-center px-4 py-2 text-sm hover:bg-gray-50 w-full"
										@click="() => onProductSelect(product)"
										@mousedown="() => productDown = true"
										@mouseup="() => productDown = false"
									>
										<span
											class="svg-icon mr-4 h-8 w-8 rounded-full bg-gray-100 p-2 text-gray-600"
											v-html="featherPackage"
										/>

										<span class="flex items-baseline">
											<span class="line-clamp-1 text-left">{{ product.name }}</span>
											<span class="ml-1 text-xs">
												({{ product.numReviews }})
											</span>
										</span>
									</button>
								</div>
							</div>

							<vuelidate-error
								:v$="v$"
								name="productName"
							/>
						</div>


						<div class="mb-8">
							<div class="mb-5 font-medium">
								What would you rate this?
							</div>
							<bar-rating-select
								:rating="data.review.rating"
								@rating-change="onRatingChange"
							/>
						</div>

						<div class="mb-8">
							<div class="mb-2 font-medium">
								Title of your review
							</div>
							<input
								v-model="data.review.header"
								class="border border-gray-200"
								:maxlength="MAX_HEADER_LENGTH"
								placeholder="Add a brief overview of your experience"
							/>
							<div class="flex justify-between">
								<vuelidate-error
									:v$="v$"
									name="reviewHeader"
								/>
								<div
									class="text-sm text-right mt-1"
									:class="[
										data.review.header && data.review.header.length === MAX_HEADER_LENGTH
											? 'text-red-400'
											: 'text-gray-400',
									]"
								>
									{{ data.review.header ? data.review.header.length : 0 }}/{{ MAX_HEADER_LENGTH }}
								</div>
							</div>
						</div>

						<div class="mb-8">
							<div class="mb-2 font-medium">
								What are your thoughts?
							</div>
							<textarea
								v-model="data.review.description"
								class="border border-gray-200"
								:placeholder="'Tell the world how you feel about this product \n\n#tag #this #review'"
								rows="4"
								:maxlength="MAX_DESCRIPTION_LENGTH"
							/>
							<div
								class="w-full text-right text-sm"
								:class="[
									data.review.description && data.review.description.length === MAX_DESCRIPTION_LENGTH
										? 'text-red-400'
										: 'text-gray-400',
								]"
							>
								{{ data.review.description ? data.review.description.length : 0 }}/{{ MAX_DESCRIPTION_LENGTH }}
							</div>
						</div>

						<div
							v-if="user.creatorProgramStatus !== CREATOR_PROGRAM_STATUS.accepted"
							class="mb-8"
						>
							<div class="mb-2 font-medium">
								Affiliate link <span class="text-gray-400">(Optional)</span>
							</div>
							<input
								v-model="data.review.affiliateLink"
								type="text"
								class="border border-gray-200 px-[8px] py-[6px]"
								placeholder="https://amazon.com/my-affiliate-link"
							/>
							<vuelidate-error
								:v$="v$"
								name="affiliateLink"
							/>
						</div>

						<div
							v-if="!isExisting"
							class="mb-8"
						>
							<div class="mb-2 font-medium">
								Model number <span class="text-gray-400">(Optional)</span>
							</div>
							<input
								type="text"
								class="border border-gray-200 px-[8px] py-[6px]"
								placeholder="Add a model number if the product has one"
								:maxlength="MAX_MODEL_NUMBER_LENGTH"
								:value="modelNumber"
								@input="onModelNumberChange"
							/>
							<div
								class="w-full text-right text-sm mt-1"
								:class="[
									data.product.modelNumber && data.product.modelNumber.length === MAX_MODEL_NUMBER_LENGTH
										? 'text-red-400'
										: 'text-gray-400',
								]"
							>
								{{ data.product.modelNumber ? data.product.modelNumber.length : 0 }}/{{ MAX_MODEL_NUMBER_LENGTH }}
							</div>
							<vuelidate-error
								:v$="v$"
								name="modelNumber"
							/>
						</div>

						<button
							v-if="!isExisting"
							class="btn btn-primary w-full flex items-center justify-center"
							type="button"
							@click="onPostReview"
						>
							Post Review
							<span
								v-if="data.isPosting"
								class="ml-2 h-[20px]"
							>
								<ring-loader
									size="20"
									color="#ffffff"
								/>
							</span>
						</button>
						<button
							v-else
							class="btn btn-primary w-full rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
							type="button"
							@click="onPostReview"
						>
							Update Review
						</button>
					</div>
				</div>
			</form>
		</div>
	</revvy-modal>
</template>

<style scoped lang="scss">
@import "../styles/variables.scss";

.fade-enter-active {
  transition: opacity 0.4s ease;
}
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0.2;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.edit-review-form {
	input {
		width: 100%;
		padding: 8px 16px;
		margin: 0;
	}

	::placeholder {
		color: $gray-400;
	}

	textarea {
		width: 100%;
		resize: none;
	}

	.progress {
		display: none;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 6px;
		border-radius: 30px;
		background: $gray-50;
	}

	.progress-bar {
		transition: width 1s;
		background: $primary;
		height: 6px;
		position: absolute;
		border-radius: 30px;
	}

	.dragover-label > * {
		pointer-events: none;
	}

	.description {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
}
</style>

<style lang="scss">
@import "../styles/variables.scss";

.rotating span.placeholder {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: 16px;
	pointer-events: none;
}

.dropdown-search {
	.vs__search {
		opacity: 1 !important;
	}
}

.vs--searchable {
	.vs__dropdown-toggle {
		padding: 0;
		box-shadow: none;
		border: 1px solid $gray-200;
	}
}

.vs__selected-options {
	padding: 0;
}

.fa-level-up-alt {
	svg {
		transform: rotate(90deg);
	}
}
</style>
