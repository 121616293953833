import {createApp} from 'vue';
import {createHead} from '@vueuse/head';
import Loading from 'vue-loading-overlay';
import Notifications from '@kyvg/vue3-notification';
import velocity from 'velocity-animate';
import store from './stores';
import router from './router';
import App from './App.vue';
import { VueReCaptcha } from 'vue-recaptcha-v3'

import 'vue-select/dist/vue-select.css';

const head = createHead();

window.$vue = createApp(App);

window.$vue.config.globalProperties.$log = console.log;

window.$vue.use(router);
window.$vue.use(store);

window.$vue.use(Loading);
window.$vue.use(Notifications, { velocity });

window.$vue.use(head);

window.$vue.use(VueReCaptcha, {
	siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
	loaderOptions: {
		autoHideBadge: true
	}
});

window.$vue.mount('#app');
