// NOTE: This file gets copied / transpiled to the web and app clients from the server, so you should be a bit careful with what you put in here
// A. don't put anything sensitive
// B. remember to only edit the file on the server because the client will get overwritten

export const USER_TYPE = {
	regular: 'regular',
	admin: 'admin',
};

export const USER_STRIPE_STATUS = {
	unconnected: 'unconnected',
	connected: 'connected',
	submitted: 'submitted',
}

export const USER_STATUS = {
	active: 'active',
	banned: 'banned',
}

export const ACTION_SIGNIN = 'sign-in';
export const ACTION_SIGNUP = 'sign-up';

export const MAX_PHONE_NUMBER_USES = 1;

export const PERMA_DELETE_USER_AFTER_DAYS = 60;


export const CREATOR_PROGRAM_STATUS = {
	notApplied: 'not-applied',
	applied: 'applied',
	accepted: 'accepted',
	rejected: 'rejected',
	banned: 'banned',
}


export const REWARD_TYPE_BOOST = 'reward-boost';
export const REWARD_TYPE_GIVEAWAY_ENTRY = 'reward-giveaway-entry';
export const REWARD_NONE = 'reward-none';


export const USER_SETTINGS_TAB = {
	account: 'account',
	emails: 'emails',
	notifications: 'notification',
};


export const MIN_CREATOR_PROGRAM_REVIEWS = 3;
