import Hashids from 'hashids';

const hashids = new Hashids('revvy', 15);

export function hash(id) {
	return hashids.encode(id);
}

export function unhash(hashID) {
	return hashids.decode(hashID)[0];
}
