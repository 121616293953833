<template>
	<span
		class="create-review"
		:class="mainClass"
	>
		<button
			type="button"
			:class="linkClass"
			class="flex items-center "
			:style="{color: linkColor}"
			@click="onClickCreateReview"
		>
			<slot name="button">
				<span :class="labelClass">
					Create a review
				</span>
			</slot>
		</button>
	</span>
</template>

<script>
import {mapGetters} from 'vuex';
import {
	faSolidPlusSquare,
	faRegularPlusSquare,
	featherPlusSquare,
} from '../utils/svgs';
import store from '../stores';

export default {
	props: {
		mainClass: '',
		linkClass: '',
		linkColor: '',
		labelClass: '',
	},
	data() {
		return {
			name: `create-review-modal`,
			review: null,
			faRegularPlusSquare,
			faSolidPlusSquare,
			featherPlusSquare,
		};
	},
	computed: {
		...mapGetters('generalStore', [
			'createReviewIsOpen',
		]),
		...mapGetters('userStore', [
			'isLoggedIn',
			'userID',
		]),
	},
	methods: {
		onClickCreateReview() {
			if (!this.isLoggedIn)
				return store.dispatch('userStore/login');

			store.commit("generalStore/setVisibleModal", this.name);

			this.$emit('open');
		},
	},
};
</script>

