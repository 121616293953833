<script setup>
import {faSolidStar} from "../utils/svgs";
import {ref, computed, onMounted, defineProps} from 'vue';

const props = defineProps({
	rating: {
		type: Number,
		default: 0 // 0-5 stars and can be in increments of .5
	},
});

const emit = defineEmits(['rating-change']);

const sliderValue = ref(props.rating ? props.rating : 2.5);

// eslint-disable-next-line vue/no-dupe-keys
const rating = computed(() => (sliderValue.value));

function changeWidth() {
	emit('rating-change', Number(sliderValue.value));
}

onMounted(() => {
	const primary = '#F1C75E';
	const gray100 = '#f3f4f6';

	document.getElementById("myRange").style.background = `linear-gradient(to right, ${primary} 0%, ${primary} 50%, ${primary} 50%, ${primary} 100%)`;

	document.getElementById("myRange").oninput = function() {
		const value = (this.value - this.min) / (this.max - this.min) * 100;
		this.style.background = `linear-gradient(to right, ${primary} 0%, ${primary} ${value}%, ${gray100} ${value}%, ${gray100} 100%)`;
	};

	const event = new Event('input');
	document.getElementById('myRange').dispatchEvent(event);
});

</script>

<template>
	<div class="flex flex-row items-center">
		<input
			id="myRange"
			v-model="sliderValue"
			type="range"
			min="0"
			max="5"
			step="0.5"
			class="slider w-full"
			@input="changeWidth"
		>

		<div class="w-[60px] flex items-center justify-end">
			<div class="mr-2 text-md">
				{{ rating }}
			</div>
			<span
				class="svg-icon w-3.5 h-3.5 fill-primary"
				v-html="faSolidStar"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import '../styles/variables.scss';

.slider {
	-webkit-appearance: none;  /* Override default CSS styles */
	appearance: none;
	height: 6px; /* Specified height */
	background: $gray-100; /* Grey background */
	outline: none; /* Remove outline */
	// opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
	// -webkit-transition: .2s; /* 0.2 seconds transition on hover */
	// transition: opacity .2s;
	padding: 0;
	border: 0;
	box-shadow: none;
}

/* Mouse-over effects */
.slider:hover {
	opacity: 1; /* Fully shown on mouse-over */
}


/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
	-webkit-appearance: none; /* Override default look */
	appearance: none;
	width: 32px; /* Set a specific slider handle width */
	height: 16px; /* Slider handle height */
	background: $revvy-primary; /* Green background */
	cursor: grab; /* Cursor on hover */
	border-radius: 30px;
}

.slider::-moz-range-thumb {
	width: 24px; /* Set a specific slider handle width */
	height: 12px; /* Slider handle height */
	background: $revvy-primary; /* Green background */
	cursor: pointer; /* Cursor on hover */
}

</style>
