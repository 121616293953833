<script setup>
import CreateReview from "./CreateReview.vue";
import store from "../stores";
import {FEED_TYPE_FOLLOWING, FEED_TYPE_FOR_YOU} from "../stores/ReviewStore";
import {
	featherHouse,
	featherLayout,
	featherLogOut,
	featherPlusCircle,
	featherUser,
	featherUserCheck,
	featherSettings
} from "../utils/svgs";
import router from "../router";
import {getAuth, signOut} from "firebase/auth";
import {initializeApp} from 'firebase/app';
import {revvyNotify} from "../utils/revvyNotify";
import {computed} from "vue";
import {CREATOR_PROGRAM_STATUS} from "../constants/User";


const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);
const app = initializeApp(firebaseConfig);

const auth = getAuth();


const currUser = computed(() => store.getters['userStore/getUser']());


const props = defineProps({
	hideCreateReview: {
		type: Boolean,
		default: false,
	},
	top: {
		type: String,
		default: null,
	},
});

const goToForYou = () => {
	store.dispatch('generalStore/goToFeed', { type: FEED_TYPE_FOR_YOU });
};

const goToFollowing = () => {
	store.dispatch('generalStore/goToFeed', { type: FEED_TYPE_FOLLOWING });
};

const goToProfile = () => {
	store.dispatch('generalStore/goToProfile');
};

const goToSettings = () => {
	store.dispatch('generalStore/goToSettings');
};

const goToDashboard = () => {
	if (currUser.value.creatorProgramStatus === CREATOR_PROGRAM_STATUS.accepted)
		store.dispatch('generalStore/goToDashboard');
	else
		router.push('/creator/join-creator-program');
};

const logout = () => {
	signOut(auth)
		.catch((e) => {
			console.error(e);
		})
		.finally(() => {
			store.dispatch('userStore/logout')
				.then(() => {
					revvyNotify({
						type: 'success',
						text: 'You\'ve logged out successfully',
					});
					router.push('/');
				});
		});
};
</script>

<template>
	<div
		:class="['z-20 absolute right-0 w-[200px] bg-white shadow border rounded-lg py-1', top ? top : 'top-[60px]']"
	>
		<div class="block lg:hidden text-sm my-2">
			<div>
				<button
					type="button"
					class=" text-sm px-4 py-3 w-full hover:bg-gray-50 flex items-center whitespace-nowrap focus:outline-none"
					@click="goToForYou"
				>
					<span
						class="svg-icon mr-3 w-4 h-4"
						v-html="featherHouse"
					/>
					For You
				</button>
			</div>
			<div>
				<button
					type="button"
					class="text-sm px-4 py-3 w-full hover:bg-gray-50 flex items-center whitespace-nowrap focus:outline-none"
					@click="goToFollowing"
				>
					<span
						class="svg-icon mr-3 w-4 h-4"
						v-html="featherUserCheck"
					/>
					Following
				</button>
			</div>
			<create-review
				v-if="!hideCreateReview"
				link-class=" text-sm px-4 py-3 w-full hover:bg-gray-50 flex items-center focus:outline-none"
				label-class="sidebar-item-name"
			>
				<template #button>
					<span
						class="svg-icon mr-3 w-4 h-4"
						v-html="featherPlusCircle"
					/>
					<span>Create a review</span>
				</template>
			</create-review>
		</div>
		<hr class="mx-4 border-gray-200 my-2 lg:hidden">
		<div class="text-gray-900 [&>button]:focus:outline-none [&>button]:py-3 text-sm my-2">
			<button
				type="button"
				class="px-4 w-full hover:bg-gray-50 flex items-center whitespace-nowrap"
				@click="goToProfile"
			>
				<span
					class="svg-icon mr-3 w-4 h-4 focus:outline-none"
					v-html="featherUser"
				/>
				Profile
			</button>

			<button
				type="button"
				class="px-4 w-full hover:bg-gray-50 flex items-center whitespace-nowrap "
				@click="goToDashboard"
			>
				<span
					class="svg-icon mr-3 w-4 h-4 focus:outline-none"
					v-html="featherLayout"
				/>
				Dashboard
			</button>
			<button
				type="button"
				class="px-4 w-full hover:bg-gray-50 flex items-center whitespace-nowrap "
				@click="goToSettings"
			>
				<span
					class="svg-icon mr-3 w-4 h-4 focus:outline-none"
					v-html="featherSettings"
				/>
				Settings
			</button>
			<hr class="mx-4 border-gray-200 my-2">
			<button
				type="button"
				class="px-4 w-full hover:bg-gray-50 flex items-center whitespace-nowrap font-normal"
				@click="logout"
			>
				<span
					class="svg-icon mr-3 w-4 h-4 focus:outline-none"
					v-html="featherLogOut"
				/>
				Log Out
			</button>
		</div>
	</div>
</template>
