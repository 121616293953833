<template>
	<div
		class="fixed top-0 z-30 h-20 w-full border-b shadow-none focus:outline-none"
		:class="[
			hideShadow ? '' : 'shadow',
			hideSearch ? '' : 'hidden sm:block',
			darkPage ? 'border-gray-800 bg-gray-950' : 'border-gray-200 bg-white',
		]"
	>
		<div class="mx-auto flex h-full w-full max-w-content items-center justify-between pl-8">
			<router-link
				to="/"
				class="flex-start flex h-12"
			>
				<span
					class="revvy-logo h-full w-auto"
					v-html="darkPage ? revvyLogoOnDark : revvyLogoAlt"
				/>
			</router-link>

			<div
				v-if="!hideSearch"
				class="mr-4 flex w-[420px] items-center"
			>
				<searchbar />
			</div>

			<div class="flex items-center">
				<create-review
					v-if="!hideCreateReview"
					link-class="hidden lg:block btn btn-outline"
					@open="closeSearch"
				>
					<template #button>
						Create a review
					</template>
				</create-review>

				<div
					v-if="!isLoggedIn"
					class="mr-6 flex flex-row items-center"
				>
					<div class="hidden lg:block">
						<button
							class="mx-4 text-gray-950 btn btn-primary"
							type="button"
							@click="login"
						>
							Log In
						</button>
					</div>

					<div class="flex items-center lg:hidden">
						<button
							type="button"
							@click="toggleMobileDropdown"
						>
							<span
								class="svg-icon w-8 h-8"
								v-html="featherMenu"
							/>
						</button>
					</div>
					<div
						v-if="visibleDropdown === 'mobile-dropdown'"
						class="small-desktop-nav-dropdown"
					>
						<div
							class="absolute right-[24px] top-[60px] rounded-lg border bg-white py-1 shadow"
						>
							<div>
								<button
									type="button"
									class="flex w-full items-center whitespace-nowrap px-4 py-2 text-xs text-gray-500 hover:bg-gray-50"
									@click="login"
								>
									<span
										class="svg-icon mr-3 h-4 w-4"
										v-html="featherUser"
									/>Sign
									In &bull; Sign Up
								</button>
							</div>

							<div class="my-2 h-[1px] w-full bg-neutral-200" />

							<div>
								<button
									type="button"
									class="flex w-full items-center whitespace-nowrap px-4 py-2 text-xs text-gray-500 hover:bg-gray-50"
									@click="goToForYou"
								>
									<span
										class="svg-icon mr-3 w-4"
										v-html="featherHouse"
									/>
									For You
								</button>
							</div>

							<div>
								<button
									type="button"
									class="flex w-full items-center whitespace-nowrap px-4 py-2 text-xs text-gray-500 hover:bg-gray-50"
									@click="goToFollowing"
								>
									<span
										class="svg-icon mr-3 w-4"
										v-html="featherUserCheck"
									/>
									Following
								</button>
							</div>
							<create-review
								v-if="!hideCreateReview"
								link-class="text-gray-500 text-xs px-4 py-2 w-full flex items-center"
							/>

							<div class="my-2 h-[1px] w-full bg-neutral-200" />

							<div class="policy-item">
								<a
									href="/terms-of-service.html"
									target="_blank"
									class="w-full whitespace-nowrap px-4 py-1 text-xs text-gray-500"
								>
									Terms of Service
								</a>
							</div>

							<div class="policy-item">
								<a
									href="/privacy-policy.html"
									target="_blank"
									class="w-full whitespace-nowrap px-4 py-1 text-xs text-gray-500 hover:bg-gray-50"
								>
									Privacy Policy
								</a>
							</div>

							<div class="policy-item">
								<a
									href="/intellectual-property-policy.html"
									target="_blank"
									class="w-full whitespace-nowrap px-4 py-1 text-xs text-gray-500 hover:bg-gray-50"
								>
									Intellectual Property Policy
								</a>
							</div>
						</div>
					</div>
				</div>

				<div
					v-else
					class="mr-6 flex flex-row items-center"
				>
					<notification-nav-item-dropdown />
					<div class="relative ml-3">
						<button
							type="button"
							class="flex items-center focus:outline-none"
							@click="toggleProfileNavDropdown"
						>
							<profile-photo
								class="mr-2 inline"
								:user-i-d="userID"
								:width="40"
								:link-to-profile="false"
							/>
						</button>

						<navbar-dropdown v-if="visibleDropdown === 'profile-nav'" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
import {
	revvyLogoAlt,
	revvyLogoOnDark,
	faSolidHouse,
	faRegularHouse,
	faRegularPeopleSimple,
	faSolidPeopleSimple,
	faRegularUserLarge,
	faRegularMoneyBill,
	faRegularRightFromBracket,
	featherMenu,
	featherUser,
	faSolidCaretDown,
	faSolidCaretUp,
	featherPlusSquare,
	featherPlusCircle,
	featherLayout,
	featherLogOut,
	featherUserCheck,
	featherHouse,
	featherDollarSign,
} from "../utils/svgs";
import NotificationNavItemDropdown from "./NotificationNavItemDropdown";
import Searchbar from "./Searchbar";
import CreateReview from "./CreateReview.vue";
import ProfilePhoto from "./ProfilePhoto";


import {FEED_TYPE_FOLLOWING, FEED_TYPE_FOR_YOU} from '../stores/ReviewStore';
import store from '../stores';
import NavbarDropdown from "./NavbarDropdown.vue";


export default {
	components: {
		NavbarDropdown,
		NotificationNavItemDropdown,
		Searchbar,
		ProfilePhoto,
		CreateReview,
	},
	props: {
		hideSearch: {
			type: Boolean,
			default: false,
		},
		hideCreateReview: {
			type: Boolean,
			default: false,
		},
		hideShadow: {
			type: Boolean,
			default: false,
		},
		darkPage: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			toDisplayDropdown: false,
			revvyLogoAlt,
			revvyLogoOnDark,
			featherMenu,
			featherUser,
			faSolidHouse,
			faRegularHouse,
			faRegularPeopleSimple,
			faSolidPeopleSimple,
			faRegularUserLarge,
			faRegularMoneyBill,
			faRegularRightFromBracket,
			faSolidCaretDown,
			faSolidCaretUp,
			featherPlusSquare,
			featherPlusCircle,
			featherLayout,
			featherLogOut,
			featherUserCheck,
			featherHouse,
			featherDollarSign,
		};
	},
	computed: {
		...mapGetters("userStore", [
			"getUser",
			"isLoggedIn",
			"username",
			"profileImage",
			"sessionLoaded",
		]),
		...mapGetters("generalStore", ["visibleDropdown"]),
		userID() {
			return this.getUser().userID;
		},
	},
	methods: {
		login() {
			store.dispatch("userStore/login", { required: false });
		},
		closeSearch() {
			store.dispatch("generalStore/closeMobileSearch");
		},
		toggleProfileNavDropdown(e) {
			e.stopPropagation();
			store.commit(
				"generalStore/setVisibleDropdown",
				this.visibleDropdown === "profile-nav" ? "" : "profile-nav"
			);
		},
		toggleMobileDropdown(e) {
			e.stopPropagation();
			store.commit(
				"generalStore/setVisibleDropdown",
				this.visibleDropdown === "mobile-dropdown" ? "" : "mobile-dropdown"
			);
		},
		goToForYou() {
			store.dispatch("generalStore/goToFeed", { type: FEED_TYPE_FOR_YOU });
		},
		goToFollowing() {
			store.dispatch("generalStore/goToFeed", { type: FEED_TYPE_FOLLOWING });
		},
	},
};
</script>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../styles/variables.scss";
@import "../styles/base.scss";

.revvy-logo :deep(svg) {
	height: 100%;
}

a {
	color: #2c3e50;

	&.router-link-exact-active {
		color: #42b983;
	}
}
</style>
