<template>
	<div>
		<small
			v-for="(error, i) of errors"
			:key="i"
			class="text-red-500"
		>
			{{ error.$message }}
		</small>
	</div>
</template>

<script>
export default {
	props: {
		'v$': { type: Object },
		'name': { type: String },
	},
	computed: {
		errors() {
			return this.v$[this.name] ? this.v$[this.name].$errors : [];
		},
	},
};
</script>

<style scoped>

</style>
