import {getField, updateField} from 'vuex-map-fields';
import _each from 'lodash/each';
import _filter from 'lodash/filter';
import {request} from '../utils/Request';
import {TYPE_UP} from "../constants/Vote";

const store = {
	namespaced: true,
	state: {
		votes: {},
	},
	getters: {
		Model: (state) => state.Model,
		getField,
		getVotesByReviewID: (state) => (reviewID) => _filter(state.votes, (vote) => vote.reviewID === reviewID).reverse(),
	},
	mutations: {
		updateField,
		setVote(state, vote) {
			state.votes[vote.voteID] = vote;
		},
		setVotes(state, votes) {
			_each(votes, (vote) => {
				state.votes[vote.voteID] = vote;
			});
		},
		unsetVote(state, voteID) {
			delete state.votes[voteID];
		},
		deleteVote(state, voteID) {
			delete state.votes[voteID];
		},
	},
	actions: {
		createVote({ commit }, { vote, reviewUserID }) {
			return new Promise((resolve, reject) => {
				request('post', 'vote', {
					vote,
					reviewUserID,
				})
					.then((data) => {
						commit('setVote', data);
						if (vote.type === TYPE_UP) {
							commit('reviewStore/incrementReviewCount', vote.reviewID, { root: true });
						}
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		deleteVote({ commit }, vote) {
			return new Promise((resolve, reject) => {
				request('delete', `vote/${vote.voteID}`)
					.then((data) => {
						commit('unsetVote', vote.voteID);
						if (vote.type === TYPE_UP) {
							commit('reviewStore/decrementReviewCount', vote.reviewID, {root: true});
						}
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
};

export default store;
