import {
	size, each, first, last, sortBy, orderBy, filter,
} from 'lodash';
import {getField, updateField} from 'vuex-map-fields';
import {request} from '../utils/Request';


const store = {
	namespaced: true,
	state: {
		commentID: 0,
		// just the whole dictionary of all comments
		comments: {},
		// dictionary of comments counts by reviewID
		commentCounts: {},
		// dictionaries of the number of  comments that still have entries
		// to be fetched from the server for a specific thread
		hasOlder: {},
		hasNewer: {},
		// dictionary of comment flags
		commentFlags: {},
	},
	getters: {
		Model: (state) => state.Model,
		getField,
		getComment: (state, getters) => () => getters.getCommentByID(state.commentID),
		getCommentByID: (state) => (commentID) => state.comments[commentID] || null,
		getCommentFlagsByCommentID: (state) => (commentID) => state.commentFlags[commentID],
		getRootCommentsByReviewID: (state) => (reviewID) => orderBy(filter(state.comments, (comment) => comment.reviewID === reviewID && comment.parentID === 0), ['createdAt'], ['asc']),
		getCommentsByReviewID: (state) => (reviewID) => orderBy(filter(state.comments, (comment) => comment.reviewID === reviewID), ['createdAt'], ['desc']),
		getReplyCommentsByParentID: (state) => (parentID) => orderBy(filter(state.comments, (comment) => comment.parentID === parentID), ['createdAt'], ['asc']),
		getTotalCommentsCountByReviewID: (state) => (reviewID) => state.commentCounts[reviewID],
		getLoadedCommentsCountByReviewID: (state, getters) => (reviewID) => size(getters.getCommentsByReviewID(reviewID)),
		hasOlderComments: (state) => (reviewID, commentID) => !!state.hasOlder[`${reviewID}-${commentID}`],
		hasNewerComments: (state) => (reviewID, commentID) => !!state.hasNewer[`${reviewID}-${commentID}`],
	},
	mutations: {
		updateField,
		setComment(state, comment) {
			state.comments[comment.commentID] = comment;
		},
		setActiveCommentID(state, commentID) {
			state.commentID = commentID;
		},
		setComments(state, comments) {
			comments.forEach((comment) => {
				state.comments[comment.commentID] = comment;
			});
		},
		setHasNewer(state, { reviewID, commentID, hasMore }) {
			state.hasNewer[`${reviewID}-${commentID}`] = hasMore;
		},
		setHasOlder(state, { reviewID, commentID, hasMore }) {
			state.hasOlder[`${reviewID}-${commentID}`] = hasMore;
		},
		setCommentFlags(state, commentFlags) {
			each(commentFlags, (commentFlag) => {
				state.commentFlags[commentFlag.commentID] = commentFlag;
			});
		},
		incrementCommentFlagCount(state, commentID) {
			const comment = state.comments[commentID];
			if (comment)
				comment.numFlags++;
		},
		decrementCommentFlagCount(state, commentID) {
			const comment = state.comments[commentID];
			if (comment)
				comment.numFlags--;
		},
		setCommentCount(state, { reviewID, count }) {
			state.commentCounts[reviewID] = count;
		},
		unsetComment(state, commentID) {
			delete state.comments[commentID];
		},
		unsetCommentFlag(state, commentID) {
			delete state.commentFlags[commentID];
		},
	},
	actions: {
		getComments({ dispatch }, {
			reviewID, parentID, offsetCommentID, order, direction,
		}) {
			return new Promise((resolve, reject) => {
				request('get', 'comment', {
					reviewID,
					parentID,
					offsetCommentID,
					direction,
					order,
					includeCount: true,
				}, { skipLoader: true })
					.then((data) => {
						dispatch('setCommentsData', { reviewID, data });
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		setCommentsData({ commit, state, dispatch }, { reviewID, data }) {
			commit('setComments', data.rows);

			const sortedRows = sortBy(data.rows, ['commentID']);
			const oldestComment = first(sortedRows);
			if (oldestComment)
				commit('setHasOlder', { reviewID, commentID: oldestComment.commentID, hasMore: data.hasOlder });
			const newestComment = last(sortedRows);
			if (newestComment)
				commit('setHasNewer', { reviewID, commentID: newestComment.commentID, hasMore: data.hasNewer });

			commit('setCommentFlags', data.commentFlags);
			commit('commentStore/setCommentFlags', data.commentFlags, { root: true });
			commit('userStore/setUsers', data.users, { root: true });

			// now also save the reply comments of these comments to the store
			each(data.children, (childrenData) => {
				dispatch('setCommentsData', { reviewID, data: childrenData });
			});
		},
		createComment({ commit, state }, comment) {
			return new Promise((resolve, reject) => {
				request('post', 'comment', comment)
					.then((data) => {
						commit('setComment', data);
						const reviewID = comment.reviewID;
						let currentCount = state.commentCounts[reviewID];
						commit('setCommentCount', { count: currentCount ? ++currentCount : 1, reviewID });
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		deleteComment({ commit, state }, commentID) {
			return new Promise((resolve, reject) => {
				request('delete', `comment/${commentID}`)
					.then((data) => {
						const comment = state.comments[commentID];
						const reviewID = comment.reviewID;
						let currentCount = state.commentCounts[reviewID];
						commit('unsetComment', commentID);
						commit('setCommentCount', { count: currentCount > 0 ? --currentCount : 0, reviewID });
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		flagComment({ commit }, commentFlag) {
			return new Promise((resolve, reject) => {
				request('post', 'commentFlag', commentFlag)
					.then((commentFlag) => {
						commit('setCommentFlags', [commentFlag]);
						commit('incrementCommentFlagCount', commentFlag.commentID);
						resolve(commentFlag);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		unflagComment({ commit }, commentID) {
			return new Promise((resolve, reject) => {
				request('delete', `commentFlag/${commentID}`)
					.then((data) => {
						commit('unsetCommentFlag', commentID);
						commit('decrementCommentFlagCount', commentID);
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
};

export default store;
