<script setup>
import {
	featherBarChart,
	featherDollarSign,
	revvyLogoOnDark,
	featherHelpCircle,
	featherPlayCircle,
	featherEdit
} from "../utils/svgs";
import ProfilePhoto from "./ProfilePhoto.vue";
import {computed} from "vue";
import store from "../stores";
import router from '../router';
import CreateReview from './CreateReview.vue';
import {CREATOR_PROGRAM_STATUS} from "../constants/User";

const user = computed(() => store.getters['userStore/getUser']());
const createReviewIsOpen = computed(() => store.getters['generalStore/createReviewIsOpen']);

const activeItemName = computed(() => {
	if (createReviewIsOpen.value) return 'create-review';
	return router.currentRoute.value.name;
});

</script>

<template>
	<div class="w-[280px] sm:w-[260px] bg-gray-950 flex-col justify-between py-6 fixed top-0 left-0 bottom-0 z-10 overflow-auto flex">
		<div class="flex flex-col items-center w-full ">
			<router-link
				to="/"
				class="flex items-center"
			>
				<span
					class="svg-icon w-[90px]"
					v-html="revvyLogoOnDark"
				/>
			</router-link>
			<div class="rounded-full text-gray-500 bg-gray-800 border border-gray-800 px-2 py-0.5 mt-3 text-xs">
				FOR CREATORS
			</div>

			<template v-if="user">
				<router-link
					to="/profile"
					class="mt-10 flex flex-col items-center"
				>
					<profile-photo
						class="inline"
						:user-i-d="user.userID"
						:width="78"
						:link-to-profile="false"
					/>

					<div class="mt-2 text-white font-semibold">
						{{ user.name }}
					</div>
					<div class="mt-1 text-gray-400 text-sm">
						{{ '@' + user.username }}
					</div>
				</router-link>
			</template>


			<div class="w-full px-6 mt-8">
				<router-link
					v-if="user"
					to="/creator/dashboard"
					class="text-md px-4 py-3 flex items-center rounded-lg border  border-gray-950"
					:class="[activeItemName === 'CreatorDashboard' ? 'bg-gray-900  border-gray-800 text-revvy' : 'bg-transparent text-gray-600']"
				>
					<span
						class="svg-icon mr-2 w-5 h-5"
						v-html="featherBarChart"
					/>
					Dashboard
				</router-link>
				<router-link
					v-if="user && user.creatorProgramStatus === CREATOR_PROGRAM_STATUS.accepted"
					to="/creator/earnings"
					class="text-md px-4 py-3 flex items-center rounded-lg border border-gray-950"
					:class="[activeItemName === 'CreatorEarnings' ? 'bg-gray-900  border-gray-800 text-revvy' : 'bg-transparent text-gray-600']"
				>
					<span
						class="svg-icon mr-2 w-5 h-5"
						v-html="featherDollarSign"
					/>
					Earnings
				</router-link>
				<router-link
					v-if="user"
					to="/creator/reviews"
					class="text-md px-4 py-3 flex items-center rounded-lg border border-gray-950"
					:class="[activeItemName === 'CreatorReviews' ? 'bg-gray-900  border-gray-800 text-revvy' : 'bg-transparent text-gray-600']"
				>
					<span
						class="svg-icon mr-2 w-5 h-5"
						v-html="featherPlayCircle"
					/>
					Reviews
				</router-link>
				<router-link
					v-if="user && user.creatorProgramStatus !== CREATOR_PROGRAM_STATUS.accepted"
					to="/creator/join-creator-program"
					class="text-md px-4 py-3 flex items-center rounded-lg border border-gray-950"
					:class="[activeItemName === 'JoinCreatorProgram' ? 'bg-gray-900  border-gray-800 text-revvy' : 'bg-transparent text-gray-600']"
				>
					<span
						class="svg-icon mr-2 w-5 h-5"
						v-html="featherEdit"
					/>
					Application
				</router-link>
			</div>

			<create-review
				link-class="btn btn-primary mt-12 inline"
				@open="closeSearch"
			>
				<template #button>
					<span class="whitespace-nowrap">Create a&nbsp;</span>review
				</template>
			</create-review>
		</div>


		<div class="w-full px-6 py-3">
			<!-- <router-link
				to="/creator/settings"
				class="text-md px-4 py-3 flex items-center rounded-lg text-gray-950 hover:text-gray-950"
			>
				<span
					class="svg-icon mr-2 w-5 h-5"
					v-html="featherSettings"
				/>
				Settings
			</router-link> -->
			<router-link
				to="/creator/faq"
				class="text-md px-4 py-3 flex items-center rounded-lg border border-gray-950"
				:class="[activeItemName === 'CreatorFAQ' ? 'bg-gray-900 border-gray-800 text-revvy' : 'bg-transparent text-gray-600']"
			>
				<span
					class="svg-icon mr-2 w-5 h-5"
					v-html="featherHelpCircle"
				/>
				FAQ
			</router-link>
		</div>
	</div>
</template>
