import axios from 'axios';
import {getAuth} from "firebase/auth";
import {initializeApp} from "firebase/app";

axios.defaults.withCredentials = true;

const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);
initializeApp(firebaseConfig);
export const auth = getAuth();

export const request = async (method, endpoint, params = null, settings = {}) => {
	let data = { params };
	if (method === 'post' || method === 'put')
		data = params;

	if (settings.sendToken) {
		const token = await auth.currentUser?.getIdToken();
		if (method === 'delete')
			data = { ...data, headers: { Authorization: `Bearer ${token}` } };
		else
			settings = { ...settings, headers: { Authorization: `Bearer ${token}` } }

		delete settings.sendToken;
	}

	return new Promise((resolve, reject) => {
		axios[method](`${process.env.VUE_APP_API_URL}/${endpoint}`, data, settings)
			.then((response) => {
				resolve(response.data);
			})
			.catch((e) => {
				let error = '';
				if (e.isAxiosError && e.response && e.response.data)
					error = e.response.data.error ? e.response.data.error : e.response.data;
				else
					error = e;
				reject(error);
			});
	});
}
