<template>
	<div class="ml-2 lg:ml-3 relative">
		<div class="relative">
			<button
				type="button"
				class="flex items-center justify-center w-12 h-12"
				@click="toggleNotificationDropdown"
			>
				<span
					v-if="hasNewNotifications"
					class="absolute top-2 right-2 w-2 h-2 rounded-full bg-revvy"
				/>
				<span
					class="svg-icon w-5 h-5 text-gray-600"
					v-html="featherBell"
				/>
			</button>
		</div>

		<div
			v-if="visibleDropdown === 'notification-nav'"
			class="absolute top-[60px] right-0 w-[350px] bg-white shadow border rounded-lg z-10 my-2"
		>
			<div
				v-for="(notificationAction, index) in allNotificationActions.slice(0,6)"
				:key="index"
				class="message relative px-4 py-2 hover:bg-gray-50 cursor-pointer whitespace-pre-wrap overflow-hidden"
				:class="{unread:isUnread(notificationAction)}"
				@click="goToNotification(notificationAction)"
			>
				<div class="flex">
					<profile-photo
						class="mr-4"
						:user="notificationAction.context.initiatorUser"
						:width="40"
					/>
					<div>
						<div
							class="text-sm"
							v-html="getMessage(notificationAction).text"
						/>
						<div
							class="text-xs text-gray-500"
							v-html="getTime(notificationAction)"
						/>
					</div>
				</div>
			</div>

			<div
				v-if="searched && !hasNotifications"
				class="p-4"
			>
				<span class="text-gray-500 font-normal">You have no notifications.</span>
			</div>
		</div>
	</div>
</template>
<script>
import {mapGetters} from 'vuex';
import {isEmpty, some} from 'lodash';
import moment from 'moment';
import ProfilePhoto from './ProfilePhoto';
import {featherBell} from '../utils/svgs';
import {hash} from '../utils/Hash';
import router from '../router';
import store from '../stores';
import {
	ACTION_TYPE_LIKE,
	ACTION_TYPE_COMMENT,
	ACTION_TYPE_REPLY, ACTION_TYPE_MENTIONED, ACTION_TYPE_FOLLOW
} from "../constants/NotificationAction";

export default {
	components: {
		ProfilePhoto,
	},
	data() {
		return {
			featherBell,
			searched: false,
			toDisplayDropdown: false,
		};
	},
	computed: {
		...mapGetters('notificationActionStore', [
			'Model',
			'allNotificationActions',
		]),
		...mapGetters('userStore', [
			'getUserByID',
		]),
		...mapGetters('generalStore', [
			'visibleDropdown',
		]),
		hasNewNotifications() {
			return some(this.allNotificationActions, (notificationAction) => notificationAction.inAppCount == 0);
		},
		hasNotifications() {
			return !isEmpty(this.allNotificationActions);
		},
		isUnread: (state) => (notificationAction) => notificationAction.inAppCount == 0,
	},
	mounted() {
		store.dispatch('notificationActionStore/getNotificationActions')
			.then(() => {
				this.searched = true;
			});
	},
	methods: {
		toggleNotificationDropdown(e) {
			e.stopPropagation();
			store.commit('generalStore/setVisibleDropdown', this.visibleDropdown === 'notification-nav' ? '' : 'notification-nav');
		},
		getMessage(notificationAction) {
			const user = notificationAction.context.initiatorUser;
			const name = user ? user.name : 'Someone';

			let message = '';
			let url = '';
			const { type, context } = notificationAction;
			switch (type) {
			case ACTION_TYPE_LIKE:
				message = `liked your review of <span class="product">${context.productName}</span>`;
				if (context.brandName)
					message += ` by <span class="product">${context.brandName}</span>`;
				url = `/review/${hash(context.reviewID)}`;
				break;
			case ACTION_TYPE_COMMENT:
				message = `commented on your review of <span class="product">${context.productName}</span>`;
				if (context.brandName)
					message += ` by <span class="product">${context.brandName}</span>`;
				url = `/review/${hash(context.reviewID)}/comment/${hash(context.commentID)}`;
				break;
			case ACTION_TYPE_REPLY:
				message = `replied to your comment on <span class="product">${context.productName}</span>`;
				if (context.brandName)
					message += ` by <span class="product">${context.brandName}</span >`;
				url = `/review/${hash(context.reviewID)}/comment/${hash(context.commentID)}`;
				break;
			case ACTION_TYPE_MENTIONED:
				message = `mentioned you in a comment on <span class="product">${context.productName}</span>`;
				if (context.brandName)
					message += ` by <span class="product">${context.brandName}</span>`;
				url = `/review/${hash(context.reviewID)}/comment/${hash(context.commentID)}`;
				break;
			case ACTION_TYPE_FOLLOW:
				message = 'started following you';
				url = `/profile/${hash(notificationAction.initiatorUserID)}`;
				break;
			default:
				break;
			}

			return {
				text: `<span class="font-semibold">${name}</span> ${message}`,
				url,
			};
		},
		getTime(notificationAction) {
			const m = moment(notificationAction.createdAt);
			const str = m.fromNow();
			return str;
		},
		goToNotification(notificationAction) {
			const url = this.getMessage(notificationAction).url;
			store.dispatch('notificationActionStore/markAsSent', [notificationAction.notificationActionID])
				.then(() => {
					router.push(url);
				});
		},
	},
};
</script>

<style lang="scss">
@import '../styles/variables.scss';

.notification-dropdown {
  .dropdown-toggle::after {
		display: none;
  }
}
.product {
	font-weight: 600;
}

		.message {
			-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				display: -webkit-box;

				&.unread::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 16px;
				width: 8px;
				height: 8px;
				border-radius: 30px;
				background: $primary;
				display: block;
			}
		}

</style>
<style scoped lang="scss">


.notification-dropdown {
	.notification-btn {
		position: relative;
		height: 40px;
		width: 40px;
		padding: 11px;
		.svg-icon {
			width: 20px;
			height: 20px;
		}
		.dot {
			position: absolute;
			right: 10px;
			top: 8px;
			width: 8px;
			height: 8px;
			border: 1px solid #F1F3F4;
		}
	}
}
</style>
