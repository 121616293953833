<template>
	<div
		:class="{
			iOS: iOS,
			'before-iOS-15': beforeIOS15,
			'mobile-search-open': mobileSearchIsOpen,
		}"
		@click="hideDropdowns"
	>
		<edit-review-modal
			v-if="review"
			name="create-review-modal"
			:initial-review="review"
		/>

		<notifications
			class="shadow-lg"
			animation-type="velocity"
			:duration="5000"
			:position="['bottom', 'right']"
		/>

		<WaitlistSplash v-if="isStaging && !isWhitelisted" />

		<template v-else>
			<mobile-nav :show-backdrop="showBackdrop" />

			<DownloadAppPrompt v-if="isDownloadAppVisible && !downloadAppSnoozed && isForYouOrFollowing" />

			<template v-if="initialized">
				<template v-if="isCreatorDashboardWelcome">
					<router-view
						v-if="sessionLoaded"
						:key="router.currentRoute.path"
					/>
				</template>
				<template v-else-if="isCreatorDashboard">
					<dashboard-nav class="sm:flex hidden" />

					<div class="w-full bg-gray-50 sm:pl-[260px]">
						<router-view
							v-if="sessionLoaded"
							:key="router.currentRoute.path"
						/>
					</div>
				</template>
				<template v-else>
					<navbar />

					<div id="body">
						<router-view
							v-if="sessionLoaded"
							:key="router.currentRoute.path"
						/>
					</div>
				</template>
			</template>
			<template v-else>
				<div class="flex h-screen w-screen items-center justify-center">
					<ellipsis-loader />
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import $ from "jquery";
import MobileDetect from "mobile-detect";
import store from "./stores";
import Navbar from "./components/Navbar";
// import WaitlistSplash from "./components/WaitlistSplash";
import DownloadAppPrompt, {
	NUM_POST_VIEWS_BEFORE_APP_DOWNLOAD,
} from "./components/DownloadAppPrompt";
import router from "./router";
import { revvyLogo } from "./utils/svgs";
import DashboardNav from "./components/DashboardNav.vue";
import EllipsisLoader from "./components/EllipsisLoader.vue";
import MobileNav from './components/MobileNav.vue';
import WaitlistSplash from "./components/WaitlistSplash.vue";
import EditReviewModal from "./components/EditReviewModal.vue";
import _cloneDeep from "lodash/cloneDeep";

export default {
	components: {
		EditReviewModal,
		WaitlistSplash,
		EllipsisLoader,
		DashboardNav,
		DownloadAppPrompt,
		// WaitlistSplash,
		Navbar,
		MobileNav,
	},
	data() {
		return {
			NUM_POST_VIEWS_BEFORE_APP_DOWNLOAD,
			initialized: false,
			revvyLogo,
			router,
			review: null,
		};
	},
	store,
	computed: {
		...mapState("generalStore", ["isWhitelisted", "downloadAppSnoozed", 'isDownloadAppVisible']),
		...mapGetters("generalStore", ["globalAlertMessage", "mobileSearchIsOpen"]),
		...mapGetters("userStore", ["sessionLoaded", "getUser"]),
		user() {
			return this.getUser();
		},
		isCreatorDashboard() {
			if (router.currentRoute.value && router.currentRoute.value.name) {
				const routeName = router.currentRoute.value.name;

				if (routeName === 'JoinCreatorProgram') return true;

				if (routeName.includes("Program")) return false;

				return routeName.includes("Creator");
			}
			return false;
		},
		isStaging() {
			return process.env.VUE_APP_MODE === 'staging';
		},
		showBackdrop() {
			if (router.currentRoute.value && router.currentRoute.value.name) {
				const path = router.currentRoute.value.path;

				if (path.includes('newsroom')) return true;
			}
			return false;		},
		isCreatorDashboardWelcome() {
			if (router.currentRoute.value && router.currentRoute.value.name)
				return router.currentRoute.value.name.includes("CreatorDashboardWelcome");

			return false;
		},
		isForYouOrFollowing() {
			if (router.currentRoute.value && router.currentRoute.value.name) {
				const routeName = router.currentRoute.value.name;

				return routeName.includes("FeedForYou") || routeName.includes("FeedFollowing");
			}

			return false;
		},
		/* whitelistException() {
			const name = router.currentRoute.value.name;

			let isException = false;
			if (name === "PrivacyPolicy") isException = true;
			else if (name === "TermsOfService") isException = true;
			else if (name === "IntellectualPropertyPolicy") isException = true;

			return isException;
		}, */
		iOS() {
			const md = new MobileDetect(window.navigator.userAgent);
			return md.os() === "iOS";
		},
		beforeIOS15() {
			// detect if we are iOS or after because the
			// mobile safar has the new address bar design
			// where it's docked at the bottom and there's no need
			// to add a bottom edge padding to account for the pull-up
			// handle
			const md = new MobileDetect(window.navigator.userAgent);
			return md.os() === "iOS" && parseInt(md.version("iOS")) < 15;
		},
	},
	mounted() {
		// create new empty placeholder object
		this.review = {
			..._cloneDeep(store.state.reviewStore.Model),
			userID: this.userID,
			rating: 5,
		};
	},
	created() {

		if (router.currentRoute.value.query.lock !== undefined)
			store.commit("generalStore/unwhitelist", {}, { root: true });

		store
			.dispatch("userStore/getSession")
			.then(() => {
				this.initialized = true;
			})
			.catch(() => {
				this.initialized = true;
			});

		$(window).on("resize", () => {
			this.detectVH();
		});
		this.detectVH();
	},
	methods: {
		// code to set the vh scss var to the window.innerHeight
		// so that it's always accurate even when the address bar
		// collapses
		detectVH() {
			const vh = window.innerHeight;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		},
		hideDropdowns() {
			store.commit("generalStore/setVisibleDropdown", "");
		},
	},
};
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/font-faces.scss";
@import "./styles/variables.scss";
@import "./styles/base.scss";

html {
	background-color: #fff;
}

body {
	overscroll-behavior: none;
}

#app {
	font-family: "Poppins", "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// color: #2c3e50;
	@apply text-gray-950; // Using Tailwind base color instead of an arbitrary value of #2c3e50.

	.vue-notification-group {
		margin: 8px;
		width: auto !important;
		@media (min-width: $sm-viewport) {
			left: auto !important;
			width: 350px !important;
			margin-top: 68px;
			margin-right: 28px;
			margin-left: 28px;
			margin-bottom: 32px;
		}
		.vue-notification {
			margin: 0;
			font-size: 16px;
		}

		.vue-notification-template::before {
			content: url('../public/x.svg');
			display: block;
			clear: both;
			float: right;
			margin-left: 10px;
			margin-top: 0;
			cursor: pointer;
		}
	}
}

#body {
	@media (min-width: $sm-viewport) {
		min-height: calc(100vh - $nav-height);
	}
}

.review-container {
	margin-bottom: calc(26px * 2);
}

@media (max-width: $sm-viewport) {
	.base-layout {
		margin: 0 auto !important;
	}
	.primary-content {
		margin: 0 !important;
		width: 100%;
		.review-container {
			margin-bottom: 0 !important;
			padding-right: 0 !important;
		}
	}
}

#footer {
	.footer-inner {
		margin: 0 auto;
		max-width: $max-content-width;
		display: flex;
		justify-content: space-between;

		.footer-left {
			.logo {
				height: $nav-height;
				padding: 10px 0;
				box-sizing: border-box;
			}
		}

		.footer-right {
			a {
				padding: 16px 8px;
			}
		}
	}
}

.mobile-search-open {
	#mobile-search {
		display: block;
	}
	#body {
		overflow: hidden;
	}
}

// spinner loader CSS
.vld-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.vld-background {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.3);
	}
	.vld-icon {
		z-index: 1;
	}
}

button:focus {
	outline: none;
}

.modal-max-height {
	display: flex;
	min-height: 300px;
	.modal-body {
		flex: 1 1 auto;
		overflow: auto;
	}
	.modal-content {
		flex: 1;
		flex-flow: column;
		max-height: calc(100vh);
		@media (min-width: $sm-viewport) {
			max-height: calc(100vh - 8rem);
		}
		.modal-header,
		.modal-footer {
			flex: 0 0 auto;
		}
	}
	&.maximize {
		.modal-content {
			height: calc(100vh - 3rem);
			@media (min-width: $sm-viewport) {
				height: calc(100vh - 5rem);
			}
		}
	}
}
.modal-max-width {
	max-width: calc(100vw - 3rem);
	@media (min-width: $sm-viewport) {
		max-width: calc(100vw - 5rem);
	}
	.modal-body {
		overflow-x: auto;
	}
	&.maximize {
		width: calc(100vw - 3rem);
		@media (min-width: $sm-viewport) {
			width: calc(100vw - 5rem);
		}
	}
}
</style>
