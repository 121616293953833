/** @type {import('tailwindcss').Config} */
module.exports = {
	purge: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
	theme: {
		extend: {
			colors: {
				revvy: '#F1C75E',
				'revvy-50': '#fffcf5',
				primary: '#F1C75E',
				// 'gray-50': '#f8f8f8' // rgb(248,248,248)
			},
			boxShadow: {
				sm: '0 2px 3px 0 rgb(0 0 5 / 0.05)',
				DEFAULT: '0 2px 8px 0 rgb(0 0 5 / 0.1), 0 1px 2px -1px rgb(0 0 5 / 0.1)',
				md: '0 3px 15px 0 rgb(0 0 5 / 0.1), 0 2px 4px -2px rgb(0 0 5 / 0.1)',
				lg: '0 4px 25px 0 rgb(0 0 5 / 0.1), 0 4px 6px -4px rgb(0 0 5 / 0.1)',
				xl: '0 6px 35px 0 rgb(0 0 5 / 0.1), 0 8px 10px -6px rgb(0 0 5 / 0.1)',
				'2xl': '0 8px 50px 0 rgb(0 0 5 / 0.25)',
				inner: 'inset 0 2px 4px 0 rgb(0 0 5 / 0.05)',
				none: 'none',
			},
			maxWidth: {
				content: '1250px',
				contentNum: 1250,
				post: '750px'
			}
		}
	}
}
