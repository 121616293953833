<script setup>
import { computed, onMounted } from "vue";
import { featherUserCheck, featherHouse } from "../utils/svgs";
import { FEED_TYPE_FOR_YOU, FEED_TYPE_FOLLOWING } from "../stores/ReviewStore";
import router from "../router";
import store from "../stores";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config.js";
import {APP_STORE_URL, GOOGLE_PLAY_STORE_URL} from "../constants/Config";

const fullConfig = resolveConfig(tailwindConfig);

const createReviewIsOpen = computed(() => store.getters["generalStore/createReviewIsOpen"]);
const currentCriteria = computed(() => store.getters["reviewStore/currentCriteria"]);
const isAdmin = computed(() => store.getters["userStore/isAdmin"]);
const isLoggedIn = computed(() => store.getters["userStore/isLoggedIn"]);

const activeItemName = computed(() => {
	if (createReviewIsOpen.value) return "create-review";
	if (router.currentRoute.value.name === "Feed") return currentCriteria.value.type;

	return router.currentRoute.value.name;
});

function goToForYou() {
	store.dispatch("generalStore/goToFeed", { type: FEED_TYPE_FOR_YOU });
}

function goToFollowing() {
	store.dispatch("generalStore/goToFeed", { type: FEED_TYPE_FOLLOWING });
}

const setPanelLeftStyle = () => {
	const maxContentWidth = fullConfig.theme.maxWidth.contentNum;
	const leftNavBar = document.getElementById("panel-left");

	if (!leftNavBar) return;
	if (!leftNavBar) return;

	if (window.innerWidth > maxContentWidth) {
		leftNavBar.style.left = (window.innerWidth - maxContentWidth) / 2 + "px";
	} else {
		leftNavBar.style.left = "0px";
	}
};

onMounted(() => {
	window.addEventListener("resize", () => {
		setPanelLeftStyle();
	});
	setPanelLeftStyle();
});
</script>

<template>
	<div
		id="panel-left"
		class="panel-left align-items-left flex-col w-full"
	>
		<a
			class="mb-2 flex cursor-pointer items-center rounded-lg px-5 py-2 text-gray-950"
			:class="[activeItemName === FEED_TYPE_FOR_YOU ? 'bg-gray-100' : 'bg-transparent']"
			@click="goToForYou"
		>
			<span
				class="svg-icon mr-4 h-5 w-5"
				v-html="featherHouse"
			/>
			<span>For You</span>
		</a>
		<a
			class="mb-8 flex cursor-pointer items-center rounded-lg px-5 py-2 text-gray-950"
			:class="[activeItemName === FEED_TYPE_FOLLOWING ? 'bg-gray-100' : 'bg-transparent']"
			@click="goToFollowing"
		>
			<span
				class="svg-icon mr-4 h-5 w-5"
				v-html="featherUserCheck"
			/>
			<span>Following</span>
		</a>

		<div class="absolute bottom-2 left-0 right-0 p-7 text-gray-500">
			<div class="mt-3 flex">
				<a
					:href="GOOGLE_PLAY_STORE_URL"
					target="_blank"
					class="mr-2 text-xs text-gray-500"
				>
					<img
						src="/img/google-play-store.png"
						alt="google play store download"
					/>
				</a>
				<a
					:href="APP_STORE_URL"
					target="_blank"
					class="text-xs text-gray-500"
				>
					<img
						src="/img/apple-app-store.png"
						alt="apple app store download"
					/>
				</a>
			</div>

			<div class="mt-4">
				<router-link
					to="/newsroom"
					class="text-xs text-gray-500 mr-2"
				>
					Newsroom
				</router-link>
				<router-link
					to="/creator-program-overview"
					class="text-xs text-gray-500 mr-2"
				>
					Revvy Creator Program
				</router-link>
				<router-link
					to="/creator/faq"
					class="text-xs text-gray-500 mr-2"
				>
					Creator Program FAQ
				</router-link>
				<router-link
					v-if="isLoggedIn"
					class="text-xs text-gray-500 mr-2"
					to="/account-settings"
				>
					Account Settings
				</router-link>
				<router-link
					v-if="isLoggedIn && isAdmin"
					class="text-xs text-gray-500"
					to="/admin"
				>
					Admin
				</router-link>
			</div>

			<div class="mt-3">
				<a
					href="/terms-of-service.html"
					target="_blank"
					class="mr-2 text-xs text-gray-500"
				>
					Terms of Service
				</a>
				<a
					href="/privacy-policy.html"
					target="_blank"
					class="text-gray-500 text-xs mr-2"
				>
					Privacy Policy
				</a>
				<a
					href="/intellectual-property-policy.html"
					target="_blank"
					class="text-xs text-gray-500"
				>
					Intellectual Property Policy
				</a>
				<a
					href="/cookie-notice.html"
					target="_blank"
					class="text-xs text-gray-500"
				>
					Cookie Notice
				</a>
			</div>

			<div class="mt-3">
				<div class="text-xs mt-2">
					contact@gorevvy.com
				</div>
			</div>

			<div class="mt-3 text-xs">
				© 2024 Revvy, Inc.
			</div>
		</div>
	</div>
</template>
