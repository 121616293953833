import {nextTick} from 'vue';
import {
	each, size, map, filter,
} from 'lodash';
import {getField, updateField} from 'vuex-map-fields';
import $ from 'jquery';
import {request} from '../utils/Request';

export const FEED_TYPE_FOR_YOU = 'FeedForYou';
export const FEED_TYPE_FOLLOWING = 'FeedFollowing';

export function newReviewCriteria(parameters = {}, settings = {}) {
	const criteria = {
		// filter criteria
		params: {
			userID: null,
			reviewID: [],
			productID: [],
			productPageID: [],
			offsetCommentID: null,
			type: '',
			limit: 8,
			offset: 0,
			includeCount: true,
		},
		settings: {
			setCurrent: true,
			skipLoader: false,
		},
		// downloaded reviews of current feed
		reviewIDs: [],
		totalCount: 0,
		endOfResults: false,
	};
	Object.assign(criteria.params, parameters);
	Object.assign(criteria.settings, settings);
	return criteria;
}

const store = {
	namespaced: true,
	state: {
		reviewID: 0, // currentReviewID
		currentCriteria: newReviewCriteria(),
		reviews: {},
		reviewFlags: {},
		videos: [], // video DOM nodes loaded on screen
		clapCount: 0,
		isGettingMoreReviews: false,
	},
	getters: {
		Model: (state) => state.Model,
		getField,
		getClapCount: (state) => state.clapCount,
		allReviews: (state) => map(state.currentCriteria.reviewIDs, (reviewID) => state.reviews[reviewID]),
		currentReview: (state, getters) => getters.getReviewByID(state.reviewID),
		currentCriteria: (state) => state.currentCriteria,
		getReviewByID: (state) => (reviewID) => state.reviews[reviewID] || null,
		getReviewsByUserID: (state) => (userID) => filter(state.reviews, { userID }),
		getReviewsByProductID: (state) => (productID) => filter(state.reviews, { productID }),
		getReviewsByProductPageID: (state, getters, rootState) => (productPageID) => {
			const { productListings } = rootState.productListingStore;

			const productIDs = map(filter(productListings, { productPageID }), 'productID');

			return filter(state.reviews, (review) => productIDs.includes(review.productID));
		},
		getReviewFlagByReviewID: (state) => (reviewID) => state.reviewFlags[reviewID],
		getVisibleHeightInViewport: (state, getters, rootState) => (el) => {
			const elementTop = $(el).offset().top;
			const elementBottom = elementTop + $(el).outerHeight();
			const viewportTop = $(window).scrollTop() + rootState.generalStore.desktopTopNavHeight;
			const viewportBottom = viewportTop + $(window).height() - rootState.generalStore.mobileBottomNavHeight;

			const elementHeight = $(el).height();

			// if completely out of viewport
			if (elementTop > viewportBottom || elementBottom < viewportTop)
				return 0;
			// if both top and bottom edge of the element is in viewport
			if (elementTop > viewportTop && elementBottom < viewportBottom)
				return elementHeight;
			// if the top edge of the element is sticking out to the top
			if (elementTop < viewportTop)
				return elementBottom - viewportTop;
			// otherwise, if the bottom edge of the element is sticking out to the bottom
			return viewportBottom - elementTop;
		},
		getVisibleWidthInViewport: () => (el) => {
			const elementLeft = $(el).offset().left;
			const elementRight = elementLeft + $(el).outerWidth();
			const viewportLeft = $(window).scrollLeft();
			const viewportBottom = viewportLeft + $(window).width();

			const elementWidth = $(el).width();

			// if completely out of viewport
			if (elementLeft > viewportBottom || elementRight < viewportLeft)
				return 0;
			// if both left and right edge of the element is in viewport
			if (elementLeft > viewportLeft && elementRight < viewportBottom)
				return elementWidth;
			// if the left edge of the element is sticking out to the left
			if (elementLeft < viewportLeft)
				return elementRight - viewportLeft;
			// otherwise, if the right edge of the element is sticking out to the right
			return viewportBottom - elementLeft;
		},
	},
	mutations: {
		updateField,
		setReview(state, review) {
			state.reviews[review.reviewID] = review;
		},
		setActiveReviewID(state, data) {
			state.reviewID = data;
		},
		setReviews(state, reviews) {
			reviews.forEach((review) => {
				state.reviews[review.reviewID] = review;
				state.currentCriteria.reviewIDs.push(review.reviewID);
			});
		},
		unsetReview(state, reviewID) {
			delete state.reviews[reviewID];
		},
		setReviewFlags(state, reviewFlags) {
			each(reviewFlags, (reviewFlag) => {
				state.reviewFlags[reviewFlag.reviewID] = reviewFlag;
			});
		},
		unsetReviewFlag(state, reviewID) {
			delete state.reviewFlags[reviewID];
		},
		incrementReviewCount(state, reviewID) {
			const review = state.reviews[reviewID];
			if (!review)
				return false;

			review.numUpvotes++;
		},
		decrementReviewCount(state, reviewID) {
			const review = state.reviews[reviewID];
			if (!review)
				return false;

			review.numUpvotes--;
		},
		scanForVideoElements(state) {
			state.videos = document.getElementsByClassName('review-video');
		},
		setClapCount(state, clapCount) {
			state.clapCount = clapCount;
		},
	},
	actions: {
		createReview({ commit, state }, { review, newBrand, newProduct }) {
			return new Promise((resolve, reject) => {
				request('post', 'review', { review, newBrand, newProduct })
					.then((data) => {
						const { review } = data;
						commit('setReview', review);
						commit('productStore/setProducts', [data.product], { root: true });
						commit('brandStore/setBrands', [data.brand], { root: true });
						state.currentCriteria.reviewIDs.push(review.reviewID);
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		updateReview({ commit, state }, { review, newBrand, newProduct }) {
			return new Promise((resolve, reject) => {
				request('put', 'review', { review, newBrand, newProduct })
					.then((data) => {
						const { review } = data;
						commit('setReview', review);
						commit('productStore/setProducts', [data.product], { root: true });
						commit('brandStore/setBrands', [data.brand], { root: true });
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		getReview({ commit, dispatch }, reviewID) {
			if (!reviewID)
				return null;

			const criteria = newReviewCriteria();
			criteria.params.reviewID = [reviewID];
			return dispatch('getReviews', criteria);
		},
		getReviews({ state, commit, dispatch }, criteria) {
			if (!criteria)
				criteria = newReviewCriteria();

			if (criteria.settings.setCurrent)
				state.currentCriteria = criteria;

			return new Promise((resolve, reject) => {
				request('get', 'review', criteria.params, criteria.settings)
					.then((data) => {
						let length;
						if (criteria.params.includeCount) {
							criteria.totalCount = data.count;
							commit('setReviews', data.rows);
							length = size(data.rows);
						} else {
							commit('setReviews', data);
							length = size(data);
						}

						criteria.params.offset += length;
						// if (criteria.params.offset >= criteria.totalCount)
						//	criteria.endOfResults = true;

						commit('setReviewFlags', data.reviewFlags);

						commit('followStore/setFollows', data.follows, { root: true });

						commit('userStore/setUsers', data.users, { root: true });

						commit('productListingStore/setProductListings', data.productListings, { root: true });
						commit('productPageStore/setProductPages', data.productPages, { root: true });
						commit('brandStore/setBrands', data.brands, { root: true });
						commit('voteStore/setVotes', data.votes, { root: true });

						// each(data.commentsDataByReviewIDs, (commentsData, reviewID) => {
						// 	// handle root comments
						// 	dispatch('commentStore/setCommentsData', { reviewID, data: commentsData }, { root: true });
						// 	commit('commentStore/setCommentCount', { reviewID, count: commentsData.count }, { root:true });
						// });

						// wait for the review videos to be rendered first
						nextTick(() => {
							commit('scanForVideoElements');
						});

						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		getMoreReviews({ state, dispatch }) {
			return new Promise((resolve, reject) => {
				if (!state.isGettingMoreReviews) {
					state.isGettingMoreReviews = true;
					dispatch('getReviews', state.currentCriteria)
						.then((data) => {
							resolve(data);
							state.isGettingMoreReviews = false;
						});
				}
			});
		},
		resetCriteria({ state }) {
			state.currentCriteria = newReviewCriteria();
		},
		deleteReview({ commit }, reviewID) {
			return new Promise((resolve, reject) => {

				request('delete', `review/${reviewID}`)
					.then((data) => {
						commit('unsetReview', reviewID);
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		flagReview({ commit }, reviewFlag) {
			return new Promise((resolve, reject) => {
				request('post', 'reviewFlag', reviewFlag)
					.then((reviewFlag) => {
						commit('setReviewFlags', [reviewFlag]);
						resolve(reviewFlag);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		unflagReview({ commit }, reviewID) {
			return new Promise((resolve, reject) => {
				request('delete', `reviewFlag/${reviewID}`)
					.then((data) => {
						commit('unsetReviewFlag', reviewID);
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		playVideosInViewport({ state, getters, dispatch }) {
			let mostVisibleDim = 0;
			let mostVisibleVideo = null;
			const inViewVideos = [];

			for (let i = 0; i < state.videos.length; i++) {
				const video = state.videos[i];

				const visibleHeight = getters.getVisibleHeightInViewport(video);
				// width is here because at one point we allowed for multiple videos and images to be in the same review so a video could be hidden sideways
				const visibleWidth = getters.getVisibleWidthInViewport(video);

				const isPlaying = !video.paused;
				const isInViewport = visibleHeight > 0 && visibleWidth > 0;
				if (isInViewport) {
					inViewVideos.push(video);
					const visibleDim = visibleHeight * visibleWidth;
					if (visibleDim > mostVisibleDim) {
						mostVisibleDim = visibleDim;
						mostVisibleVideo = video;
					}
				} else if (isPlaying) { // stop any video that's out of the viewport that are still playing
					video.pause();
				}
			}

			// there are two reasons a video could be paused. 1) the user paused it, 2) it's not the most visible video
			// so if the user paused it we want to make sure to not start playing it again
			for (let i = 0; i < inViewVideos.length; i++) {
				const video = inViewVideos[i];

				const isPlaying = !video.paused;
				if (video === mostVisibleVideo) {
					if (!isPlaying && !video.classList.contains('paused')) {
						video.play().catch(() => {
							// ignore error thrown when video link is dead
						});
					}
				} else if (isPlaying) {
					video.pause();
				}
			}
		},
		getClapCount({ commit }) {
			return new Promise((resolve, reject) => {
				request('get', 'clap-count')
					.then((clapCount) => {
						commit('setClapCount', clapCount);
						resolve(clapCount);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
};

export default store;
