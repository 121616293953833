import {getField, updateField} from 'vuex-map-fields';
import each from 'lodash/each';
import {request} from '../utils/Request';
import {S3_BASE_URL} from '../constants/Review';
import {hash} from '../utils/Hash';

const store = {
	namespaced: true,
	state: {
		brandID: 0,
		brands: {},
		currentBrands: {},
	},
	getters: {
		Model: (state) => state.Model,
		getField,
		getBrand: (state, getters) => () => getters.getBrandByID(state.brandID),
		getBrandByID: (state) => (brandID) => state.brands[brandID] || null,
		getBrands: (state) => () => state.brands,
		getBrandSearchURL: (state, getters) => (brandID) => {
			const brand = getters.getBrandByID(brandID);
			return `/search/brands/${escape(brand.name)}`;
		},
		getBrandPageURL: (state, getters) => (brandID) => {
			const brand = getters.getBrandByID(brandID);
			return `/brand-page/${hash(brandID)}`;
		},
		getBrandLogoURL: (state, getters) => (brandID) => {
			const brand = getters.getBrandByID(brandID);
			if (brand && brand.logoKey)
				return `${S3_BASE_URL}/${brand.logoKey}`;

			return '';
		},
		getCurrentBrands: (state) => Object.values(state.currentBrands),
	},
	mutations: {
		updateField,
		setBrand(state, brand) {
			state.brands[brand.brandID] = brand;
		},
		setActiveBrandID(state, brandID) {
			state.brandID = brandID;
		},
		setBrands(state, brands) {
			this.currentBrands = {};
			each(brands, (brand) => {
				state.brands[brand.brandID] = brand;
				state.currentBrands[brand.brandID] = brand;
			});
		},
	},
	actions: {
		getBrand({ commit }, brandID) {
			if (!brandID)
				return null;

			return new Promise((resolve, reject) => {
				request('get', `brand/${hash(brandID)}`)
					.then((data) => {
						commit('setBrand', data);
						commit('setActiveBrandID', data.brandID);
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		searchBrands({ commit }, {
			keywords,
			settings = {
				skipLoader: false,
			},
		}) {
			return new Promise((resolve, reject) => {
				request('get', 'brand', {
					keywords,
				}, settings)
					.then((data) => {
						commit('setBrands', data);
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
};

export default store;
