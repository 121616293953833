<script setup>
import {ref} from 'vue';
import store from '../stores';
import {featherX} from "../utils/svgs";

const props = defineProps({
	name: String,
	title: String,
	size: {
		type: String,
		default: 'md',
	},
});

const visibleModals = ref(store.getters['generalStore/visibleModals']);

const closeModal = () => {
	store.commit('generalStore/closeModal', props.name);
};


</script>

<template>
	<div
		v-if="visibleModals[name]"
		class="fixed z-50"
	>
		<div
			class="bg-[#30303055] fixed inset-0 flex items-center justify-center"
			@click="closeModal"
		/>
		<div
			:class="{'max-w-[800px]': size === 'lg', 'max-w-[500px]': size === 'md', 'max-w-[320px]': size === 'sm'}"
			class="w-full max-h-[90vh] fixed bg-white shadow-lg rounded-lg cursor-default left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] flex flex-col overflow-hidden"
		>
			<button
				v-if="size !== 'sm'"
				class="absolute top-2 right-2"
				type="button"
				@click="closeModal"
			>
				<span
					class="text-gray-500 svg-icon w-6 h-6 rounded-full"
					v-html="featherX"
				/>
			</button>

			<div
				class="h-full w-full overflow-auto overflow-x-hidden"
				:class="{'p-6': size === 'sm' || size === 'md', 'p-10': size === 'lg'}"
			>
				<div
					v-if="title"
				>
					<h2 class="text-2xl font-bold mb-6">
						{{ title }}
					</h2>
				</div>

				<slot />
			</div>
		</div>
	</div>
</template>
