import {getField, updateField} from 'vuex-map-fields';
import {each, find, cloneDeep, filter, isEmpty, includes} from 'lodash';
import moment from 'moment';
import {request} from '../utils/Request';

const PROCESS_FREQUENCY = 15000; // every x ms

const store = {
	namespaced: true,
	state: {
		viewCount: 0, // how many views a user has gotten across all their reviews (or their reviews for x amount of time)
		viewsByReviewID: {}, // instead of indexing the views by viewID, because some views can be pending, it's more useful to index by the reviewID
		timer: 0,
		queue: [],
	},
	getters: {
		Model: (state) => state.Model,
		getField,
	},
	mutations: {
		updateField,
		setView(state, view) {
			if (!view)
				return;

			state.viewsByReviewID[view.reviewID] = view;
		},
	},
	actions: {
		createView({ state, commit, rootGetters }, reviewID) {
			if (!rootGetters['userStore/isLoggedIn'])
				return;

			const userID = rootGetters['userStore/userID'];
			const view = {...cloneDeep(state.Model),
				userID,
				reviewID,
				viewedAt: moment().toISOString(),
			};

			commit('setView', view);

			state.queue.push(view);
		},
		stopView({ state, rootGetters }, reviewID) {
			if (!rootGetters['userStore/isLoggedIn'])
				return;

			let view = state.viewsByReviewID[reviewID];
			if (!view)
				return;

			view.stoppedAt = moment().toISOString();
		},
		getViewCount({ commit }) {
			return new Promise((resolve, reject) => {
				request('get', 'view-count')
					.then((view) => {
						resolve(view);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		processQueue({state, commit}) {
			// grab views that have been stopped;
			const stoppedViews = []
			const currentViews = []; // technically there should only be one, but just in case

			each(state.queue, (view) => {
				if (view.stoppedAt)
					stoppedViews.push(view);
				else
					currentViews.push(view);
			} );

			if(isEmpty(stoppedViews))
				return Promise.resolve([]);

			// remove stopped views from the queue immediately so that
			// if the next timer kicks in and this is still running,
			// we won't double process
			state.queue = currentViews;

			return new Promise((resolve, reject) => {
				let processedSuccessfully = false;
				request('post', 'process-views', stoppedViews, { skipLoader: true })
					.then((views) => {
						processedSuccessfully = true;

						each(views, view => {
							commit('setView', view);
						})

						resolve(views);
					})
					.catch((error) => {
						// since catch() will fire even when the AJAX call succeeded, but then it encounters
						// some Javascript error in the .then() block or the callback tied to the resolve(),
						// we need the processedSuccessfully flag to make sure we don't double submit to the server
						if(!processedSuccessfully) {
							// move these views back to the queue
							state.queue = [...state.queue, ...stoppedViews];
						}

						reject(error);
					});
			});
		},
		startProcessing({state, dispatch}) {
			state.timer = setInterval(() => {
				dispatch('processQueue');
			}, PROCESS_FREQUENCY);
		},
		stopProcessing({state}) {
			clearInterval(state.timer);
			state.timer = 0;
		},
	},
};

export default store;
