import {getField, updateField} from 'vuex-map-fields';
import {each} from 'lodash';
import $ from 'jquery';
import Cookies from 'js-cookie';
import {FEED_TYPE_FOLLOWING, FEED_TYPE_FOR_YOU, newReviewCriteria} from './ReviewStore';
import {newProductPageCriteria} from './ProductPageStore';
import router from '../router';

const WHITELIST_ITEM = 'revvy-whitelisted';
const DOWNLOAD_APP_SNOOZED = 'revvy-download-app-snoozed';

const isPrerender = navigator.userAgent.indexOf('Prerender') !== -1;

const store = {
	namespaced: true,
	state: {
		desktopTopNavHeight: 0,
		mobileBottomNavHeight: 0,
		isWhitelisted: isPrerender || !!Cookies.get(WHITELIST_ITEM),
		downloadAppSnoozed: isPrerender,
		isDownloadAppVisible: false,
		globalAlertMessage: '',
		mobileSearchIsOpen: false,
		searchbarInputValue: '',
		searchKeywords: '',
		searchBrandName: '',
		searchType: '',
		videosAreMuted: true,
		visibleDropdown: '',
		visibleModals: {},
	},
	getters: {
		Model: (state) => state.Model,
		getField,
		globalAlertMessage: (state) => state.globalAlertMessage,
		mobileSearchIsOpen: (state) => state.mobileSearchIsOpen,
		createReviewIsOpen: (state) => state.visibleModals['edit-review'],
		replaceWithSVG: (state) => (event, svg) => {
			const className = event.currentTarget.className;
			$(event.currentTarget)
				.replaceWith($(`<div class="${className} flex justify-center items-center"><span class="svg-icon">${svg}</span>`));
		},
		visibleDropdown: (state) => state.visibleDropdown,
		visibleModals: (state) => state.visibleModals,
	},
	types: {
		INIT_SUCCESS: 'INIT_SUCCESS',
		INIT_FAIL: 'INIT_FAIL',
	},
	mutations: {
		updateField,
		setIsDownloadAppVisible(state, isVisible) {
			state.isDownloadAppVisible = isVisible;
		},
		whitelist(state) {
			Cookies.set(WHITELIST_ITEM, true);
			state.isWhitelisted = true;
		},
		unwhitelist(state) {
			Cookies.remove(WHITELIST_ITEM);
			state.isWhitelisted = false;
		},
		snoozeDownloadAppPrompt(state) {
			state.downloadAppSnoozed = true;
		},
		muteVideos(state) {
			state.videosAreMuted = true;
		},
		unmuteVideos(state) {
			state.videosAreMuted = false;
		},
		INIT_SUCCESS(state, data) {
			// Copy Model schemas and constants passed from
			// the server side to each corresponding
			// Vuex store's Model getter
			each(data.Models, (serverModel, modelName) => {
				const firstLower = modelName.charAt(0).toLowerCase() + modelName.substring(1);
				const modelStoreName = `${firstLower}Store`;

				if (this.state[modelStoreName])
					this.state[modelStoreName].Model = serverModel;
			});
		},
		setVisibleDropdown(state, dropdown) {
			state.visibleDropdown = dropdown;
		},
		closeModal(state, modalName) {
			delete state.visibleModals[modalName];

			// add class to the body tag to prevent scrolling
			document.body.classList.remove('modal-open');
		},
		setVisibleModal(state, modalName) {
			state.visibleModals[modalName] = true;

			// add class to the body tag to prevent scrolling
			document.body.classList.add('modal-open');
		},
	},
	actions: {
		goToProfile({ dispatch, rootGetters }, { userHashID = '' } = {}) {
			const targetRoute = `/profile${userHashID ? `/${userHashID}` : ''}`;

			const isLoggedIn = rootGetters['userStore/isLoggedIn'];
			if (!isLoggedIn)
				return dispatch('userStore/login', {
					redirectURL: targetRoute,
				}, { root: true });

			if (router.currentRoute.value.path !== targetRoute)
				router.push(targetRoute);
		},
		goToSettings({ dispatch, rootGetters }, { userHashID = '' } = {}) {
			const targetRoute = `/account-settings${userHashID ? `/${userHashID}` : ''}`;

			const isLoggedIn = rootGetters['userStore/isLoggedIn'];
			if (!isLoggedIn)
				return dispatch('userStore/login', {
					redirectURL: targetRoute,
				}, { root: true });

			if (router.currentRoute.value.path !== targetRoute)
				router.push(targetRoute);
		},
		goToDashboard({ dispatch, rootGetters }, { userHashID = '' } = {}) {
			const targetRoute = `/creator/dashboard`;

			const isLoggedIn = rootGetters['userStore/isLoggedIn'];
			if (!isLoggedIn)
				return dispatch('userStore/login', {
					redirectURL: targetRoute,
				}, { root: true });

			if (router.currentRoute.value.path !== targetRoute)
				router.push(targetRoute);
		},
		goToEarnings({ dispatch, rootGetters }, { userHashID = '' } = {}) {
			const targetRoute = `/creator/earnings${userHashID ? `/${userHashID}` : ''}`;

			const isLoggedIn = rootGetters['userStore/isLoggedIn'];
			if (!isLoggedIn)
				return dispatch('userStore/login', {
					redirectURL: targetRoute,
				}, { root: true });

			if (router.currentRoute.value.path !== targetRoute)
				router.push(targetRoute);
		},
		goToFeed({ commit, dispatch, rootGetters }, { type = FEED_TYPE_FOR_YOU }) {
			dispatch('closeMobileSearch');

			dispatch('resetCriteria');

			if (type === FEED_TYPE_FOLLOWING) {
				const isLoggedIn = rootGetters['userStore/isLoggedIn'];
				if (!isLoggedIn)
					return dispatch('userStore/login', {}, { root: true });
			}

			if (type === FEED_TYPE_FOR_YOU && router.currentRoute.value.name !== 'FeedForYou')
				router.push('/');

			if (type === FEED_TYPE_FOLLOWING && router.currentRoute.value.name !== 'FeedFollowing')
				router.push('/feed/following');

			dispatch('reviewStore/getReviews', newReviewCriteria({
				type,
			}), { root: true });
		},
		goToSearch() {
			if (router.currentRoute.value.name !== 'Search')
				router.push('/search?keywords=apple&tab=reviews');
		},
		goToBrand({}, brandName) {
			brandName = encodeURIComponent(brandName);

			if (router.currentRoute.value.name !== 'SearchBrands')
				router.push(`/search/brands/${brandName}`);
		},
		closeMobileSearch({ state }) {
			state.mobileSearchIsOpen = false;
		},
		search({ state, dispatch }, {
			keywords, type = '', setCurrent = true,
		}) {
			return new Promise((resolve) => {
				state.searchKeywords = keywords;
				state.searchType = type;

				const promises = [];
				const result = {
					reviews: [],
					products: [],
					brands: [],
				};

				if (!type || type === 'products' || type === 'all') {
					promises.push(
						new Promise((resolve) => {
							dispatch('productPageStore/searchProductPages', newProductPageCriteria(
								{ keywords },
								{ setCurrent, skipLoader: true },
							), { root: true })
								.then((data) => {
									result.products = data.rows;
									resolve(data.rows);
								});
						}),
					);
				}
				if (type === 'brands' || type === 'all') {
					promises.push(
						new Promise((resolve) => {
							dispatch('brandStore/searchBrands', {
								keywords,
								settings: { skipLoader: true },
							}, { root: true })
								.then((data) => {
									result.brands = data;
									resolve(data);
								});
						}),
					);
				}
				if (type === 'reviews' || type === 'all') {
					promises.push(
						new Promise((resolve) => {
							dispatch('reviewStore/getReviews', newReviewCriteria(
								{ keywords },
								{ setCurrent, skipLoader: true },
							), { root: true })
								.then((data) => {
									result.reviews = data.rows;
									resolve(data.rows);
								});
						}),
					);
				}

				Promise.all(promises).then((res) => {
					resolve(result);
				});
			});
		},
		resetCriteria({ state, dispatch }) {
			state.searchKeywords = '';
			dispatch('productStore/resetCriteria', {}, { root: true });
			dispatch('reviewStore/resetCriteria', {}, { root: true });
		},
		detectNavHeights({ state }) {
			state.desktopTopNavHeight = $('#nav').height();
			state.mobileBottomNavHeight = $('.mobile-bottom-nav').height();
		},
	},
};

export default store;
