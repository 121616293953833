<script setup>
import { ref, computed } from "vue";
import { request } from "../utils/Request";
import {featherBox, featherGift, featherSearch} from "../utils/svgs";
import store from "../stores";
import { useRouter } from "vue-router";

const router = useRouter();

const input = ref("");
const prodSearchResults = ref([]);
const catSearchResults = ref([]);
const showSearchResults = ref(false);
const visibleDropdown = computed(() => store.getters["generalStore/visibleDropdown"]);

const brandIDProductPageMap = ref({});

const getBrandName = (brandID) => {
	if (!brandIDProductPageMap.value[brandID]) return "";

	return brandIDProductPageMap.value[brandID].name;
};

const onInput = async (e) => {
	store.commit("generalStore/setVisibleDropdown", "searchbar");
	input.value = e.target.value;

	const catRes = await request('get', 'category', {
		keywords: e.target.value.trim(),
	});
	if (catRes.status) catSearchResults.value = catRes.value.categories;

	const prodRes = await request("get", "product-page", {
		keywords: e.target.value.trim(),
	});

	for (let i = 0; i < prodRes.brands.length; i++) {
		const brand = prodRes.brands[i];
		brandIDProductPageMap.value[brand.brandID] = brand;
	}

	prodSearchResults.value = prodRes.productPages;
	showSearchResults.value = true;
};

const onClick = (e) => {
	e.stopPropagation();
	store.commit("generalStore/setVisibleDropdown", "searchbar");
};

const onEnter = () => {
	store.commit("generalStore/setVisibleDropdown", "");

	const { query } = router.currentRoute.value;

	let tab = "reviews";
	if (query.tab) tab = query.tab;

	let searchUrl = '/search?';
	if (input.value) searchUrl += `keywords=${input.value}&`;
	if (tab) searchUrl += `tab=${tab}`;

	router.push(searchUrl);
};

router.isReady().then(() => {
	const { query } = router.currentRoute.value;

	input.value = query.keywords;

	if (input.value && input.value.length > 0) {
		request("get", "product-page", {
			keywords: input.value,
		}).then((data) => {
			for (let i = 0; i < data.brands.length; i++) {
				const brand = data.brands[i];
				brandIDProductPageMap.value[brand.brandID] = brand;
			}

			prodSearchResults.value = data.productPages;
		});
	}
});
</script>

<template>
	<div class="relative flex w-full items-center">
		<div class="w-full">
			<span
				class="svg-icon absolute left-4 top-3 h-5 w-5 text-gray-400"
				v-html="featherSearch"
			/>
			<input
				class="w-full rounded-full border border-gray-300 bg-gray-50 py-3 pl-12 pr-6 text-sm placeholder:text-gray-500 focus:border-gray-950"
				placeholder="Search for product reviews..."
				spellcheck="false"
				:value="input"
				@input="onInput"
				@click="onClick"
				@keyup.enter="onEnter"
			/>
		</div>

		<div
			v-if="visibleDropdown === 'searchbar'"
			class="absolute left-0 right-0 top-[64px] rounded-lg bg-white shadow-md"
		>
			<template v-if="prodSearchResults.length">
				<a
					v-for="productPage in prodSearchResults.slice(0, 4)"
					:key="productPage.productPageID"
					:href="'/search?tab=reviews&productPageID=' + productPage.productPageID"
					class="flex items-center px-5 py-3 text-sm hover:bg-gray-50"
				>
					<span
						class="svg-icon mr-4 h-9 w-9 rounded-full bg-gray-100 p-2 text-gray-600"
						v-html="featherGift"
					/>

					<div>
						<span>{{ productPage.name }}</span>
						<span
							v-if="productPage.attributes"
							class="ml-1"
						>{{ productPage.attributes.replace('###', ' ') }}</span>
						<span
							v-if="getBrandName(productPage.brandID)"
							class="font-semibold"
						>&nbsp;by</span>
						<span v-if="getBrandName(productPage.brandID)">&nbsp;{{ getBrandName(productPage.brandID) }}</span>
					</div>

				</a>
			</template>
			<template v-if="catSearchResults.length">
				<a
					v-for="category in catSearchResults.slice(0, 3)"
					:key="category.categoryID"
					:href="'/search?tab=reviews&categoryID=' + category.categoryID"
					class="flex items-center px-5 py-3 text-sm hover:bg-gray-50"
				>
					<span
						class="svg-icon mr-4 h-9 w-9 rounded-full bg-gray-100 p-2 text-gray-600"
						v-html="featherBox"
					/>

					<div>
						<span>{{ category.categoryName }}</span>
					</div>

				</a>
			</template>
		</div>
	</div>
</template>
