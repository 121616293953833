// NOTE: This file gets copied / transpiled to the web and app clients from the server, so you should be a bit careful with what you put in here
// A. don't put anything sensitive
// B. remember to only edit the file on the server because the client will get overwritten

export const PLACEHOLDERS = [
	{ brand: "Apple", product: "iPhone 15" },
	{ brand: "Samsung", product: "Galaxy S23" },
	{ brand: "Microsoft", product: "Surface Laptop 4" },
	{ brand: "Sony", product: "PlayStation 5" },
	{ brand: "LG", product: "OLED TV" },
	{ brand: "Dell", product: "XPS 15" },
	{ brand: "HP", product: "Spectre x360" },
	{ brand: "Lenovo", product: "ThinkPad X1 Carbon" },
	{ brand: "ASUS", product: "ROG Zephyrus" },
	{ brand: "Acer", product: "Predator Helios" },
	{ brand: "Panasonic", product: "Lumix GH6" },
	{ brand: "Philips", product: "Hue Smart Lights" },
	{ brand: "Bose", product: "QuietComfort 45" },
	{ brand: "Sennheiser", product: "HD 660 S" },
	{ brand: "JBL", product: "Flip 5" },
	{ brand: "Canon", product: "EOS R5" },
	{ brand: "Nikon", product: "Z9" },
	{ brand: "GoPro", product: "HERO10 Black" },
	{ brand: "Fujifilm", product: "X-T4" },
	{ brand: "Olympus", product: "OM-D E-M10 Mark IV" },
	{ brand: "Tesla", product: "Model S" },
	{ brand: "Toyota", product: "RAV4" },
	{ brand: "Ford", product: "F-150 Lightning" },
	{ brand: "BMW", product: "i4" },
	{ brand: "Audi", product: "e-tron" },
	{ brand: "L'Oréal", product: "Revitalift Serum" },
	{ brand: "Estée Lauder", product: "Advanced Night Repair" },
	{ brand: "Clinique", product: "Dramatically Different Moisturizing Lotion" },
	{ brand: "Maybelline", product: "Fit Me Foundation" },
	{ brand: "MAC", product: "Studio Fix Fluid" },
	{ brand: "Ikea", product: "Billy Bookcase" },
	{ brand: "Pottery Barn", product: "Pearce Sectional Sofa" },
	{ brand: "The Home Depot", product: "RIDGID Power Tools" },
	{ brand: "Lowes", product: "Kobalt Tools" },
	{ brand: "Wayfair", product: "Brookwood Cabinets" },
	{ brand: "Nintendo", product: "Switch OLED" },
	{ brand: "Sega", product: "Genesis Mini" },
	{ brand: "EA Sports", product: "FIFA 23" },
	{ brand: "Ubisoft", product: "Assassin's Creed Valhalla" },
	{ brand: "Activision", product: "Call of Duty: Vanguard" },
	{ brand: "Logitech", product: "MX Master 3" },
	{ brand: "Razer", product: "DeathAdder V2" },
	{ brand: "Corsair", product: "K95 RGB Platinum" },
	{ brand: "AMD", product: "Ryzen 9 5900X" },
	{ brand: "Intel", product: "Core i9-11900K" },
	{ brand: "Fitbit", product: "Charge 5" },
	{ brand: "Garmin", product: "Fenix 6" },
	{ brand: "Polar", product: "Vantage V2" },
	{ brand: "Suunto", product: "9 Peak" },
	{ brand: "Casio", product: "G-Shock" },
];
