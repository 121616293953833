<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import $ from 'jquery';
import {
	faDuotoneBadgeCheck,
} from '../utils/svgs';
import { S3_BASE_URL } from '../constants/Review';
import { hash } from '../utils/Hash';
import axios from "axios";
import _cloneDeep from "lodash/cloneDeep";
import EllipsisLoader from "./EllipsisLoader.vue";
import { faSolidUser } from "../utils/svgs";

const store = useStore();

const props = defineProps({
	userID: {
		type: Number,
		default: 0,
	},
	user: {
		type: Object,
		default: null,
	},
	displayBadge: {
		type: Boolean,
		default: false,
	},
	width: {
		type: Number,
		default: 50,
	},
	linkToProfile: {
		type: Boolean,
		default: true,
	},
});

const emit = defineEmits(['on-click']);

const backgroundImage = ref('');
const profileImageURL = ref('');
const hasProfileImage = ref(true);
const isUploading = ref(false);
const file = ref(null);

const userObj = computed(() => {
	if (props.user)
		return props.user;

	return store.getters['userStore/getUserByID'](props.userID);
});

const userHashID = computed(() => {
	return hash(props.userID);
});

const profilePhotoPlaceholder = computed(() => {
	if (!userObj.value)
		return faSolidUser;

	if (!userObj.value.name)
		return userObj.value.username.slice(0, 1).toUpperCase();

	const name = userObj.value.name.toUpperCase().split(' ');
	const firstWord = name[0];
	let secondWord;
	if (name.length > 1)
		secondWord = name[1];
	let text = '';
	if (secondWord != null && secondWord.length > 0)
		text = firstWord[0] + secondWord[0];
	else
		text = userObj.value.name === '' ? '' : firstWord[0];

	return text;
});

onMounted(() => {
	if (!userObj.value) {
		hasProfileImage.value = false;
		return;
	}

	const profilePhotoURL = `${S3_BASE_URL}/${userObj.value.profilePhotoKey}_sm`;
	$(`<img src="${profilePhotoURL}" alt="profile">`)
		.on('load', () => {
			backgroundImage.value = `url(${profilePhotoURL}`;
		})
		.on('error', () => {
			hasProfileImage.value = false;
		});
});

const onClick = () => {
	if (!props.linkToProfile)
		return false;

	store.commit('generalStore/closeModal', `followers-modal-${props.userID}`);
	store.commit('generalStore/closeModal', `following-modal-${props.userID}`);
	emit('on-click');

	if (isOwnProfile()) {
		file.value.click();
	} else {
		store.dispatch('generalStore/goToProfile', { userHashID: userHashID.value });
	}
};

const onFileSelected = (e) => {
	isUploading.value = true;

	const file = e.target.files[0];

	const formData = new FormData();
	formData.append('file', file);

	axios.post(`${process.env.VUE_APP_API_URL}/upload-file`, formData)
		.then((res) => {
			const user = _cloneDeep(store.getters['userStore/getUserByID'](props.userID));
			user.profilePhotoKey = res.data.key;

			store.dispatch('userStore/updateUser', user);

			const bgImage = `${S3_BASE_URL}/${user.profilePhotoKey}_sm`;

			backgroundImage.value = `url(${bgImage}`;

			isUploading.value = false;
		});
};

const isOwnProfile = () => {
	return store.getters['userStore/getUser']() && store.getters['userStore/getUser']().userID === props.userID;
};
</script>


<template>
	<div class="ProfilePhoto-root">
		<input
			ref="file"
			type="file"
			accept=".png,.jpg"
			style="display: none;"
			@change="onFileSelected"
		>
		<div
			:class="['p-0 profile-button cursor-pointer']"
			@click="onClick"
		>
			<div
				class="profile-image"
				:class="{'empty':!profileImageURL}"
				:style="{
					backgroundImage,
					width: `${width}px`,
					height: `${width}px`,
				}"
				:title="userObj? `@${userObj.username}` : ''"
			>
				<div
					v-if="isUploading"
				>
					<ellipsis-loader />
				</div>
				<div
					v-if="!hasProfileImage"
					class="text-gray-600"
					:style="{fontSize: `${width / 2.5}px`}"
					v-html="profilePhotoPlaceholder"
				>
				</div>
			</div>
		</div>
		<span
			v-if="displayBadge"
			class="profile-badge"
			v-html="faDuotoneBadgeCheck"
		/>
	</div>
</template>


<style lang="scss">
.ProfilePhoto-root {
	.profile-image {
		svg {
			width: 14px;
		}
	}
}
</style>

<style lang="scss" scoped>
@import '../styles/variables';

.ProfilePhoto-root {
	.profile-image {
		display: flex;
		background-color: $gray-100;
		background-size: cover;
		background-position: center;
		border-radius: 50%;
		position: relative;
		&.empty {
			fill: $secondary;
		}
		align-items: center;
		justify-content: center;
	}

	.profile-button {
		text-decoration: none;
	}

	.svg-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $DG8;
		width: 24px;
		height: 24px;
	}

	.profile-badge {
		position: absolute;
		top: -6px;
		right: -6px;
		width: 20px;
		height: 20px;
		fill: dodgerblue;
		color: dodgerblue;
	}
}
</style>
