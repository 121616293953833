import {request} from '../utils/Request';


const store = {
	namespaced: true,
	state: {
		blockUsers: {},
	},
	getters: {
		Model: (state) => state.Model,
		getBlockUser: (state) => (blockedUserID, blockersUserID) => {
			let blockUser = null;
			state.blockUsers.forEach(user => {
				if (user.blockedUserID === blockedUserID && user.blockersUserID === blockersUserID)
					blockUser = user;
			});
			return blockUser;
		},
	},
	mutations: {
		setBlockUsers(state, blockUsers) {
			blockUsers.forEach((user) => {
				state.blockUsers[user.blockUserID] = user;
			});
		},
		setBlockUser(state, user) {
			state.blockUsers[user.blockUserID] = user;
		},
		removeBlockUser(state, blockUserID) {
			delete state.blockUsers[blockUserID];
		}
	},
	actions: {
		fetchBlockUser({ commit }, data) {
			return new Promise((resolve, reject) => {
				request('get', `blockUser?blockedUserID=${data.blockedUserID}&blockersUserID=${data.blockersUserID}`)
					.then((data) => {
						commit('setBlockUsers', data);

						resolve(data);
					});
			});
		},
		blockUser({ commit }, data) {
			return new Promise((resolve, reject) => {
				request('post', 'blockUser', data)
					.then((data) => {
						commit('setBlockUser', data);

						resolve(data);
					});
			});
		},
		unblockUser({ commit }, blockUserID) {
			return new Promise((resolve, reject) => {
				request('delete', `blockUser/${blockUserID}`)
					.then((data) => {
						if (data) {
							commit('removeBlockUser', blockUserID);

							resolve(data);
						} else {
							reject(data);
						}
					});
			});
		}
	},
};

export default store;
