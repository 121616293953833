<script setup>
import FeedLeftSidebar from "./FeedLeftSidebar.vue";
import DashboardNav from "./DashboardNav.vue";
import {featherMenu} from "../utils/svgs";
import {computed, ref, defineProps} from "vue";
import router from "../router";
import store from "../stores";

const props = defineProps({
	showBackdrop: { type: Boolean, default: false },
});

const showLeftSidebar = ref(false);

const onNavClick = () => {
	showLeftSidebar.value = !showLeftSidebar.value;
};

router.beforeEach((to, from, next) => {
	showLeftSidebar.value = false;
	next();
});

const isLoggedIn = computed(() => store.getters["userStore/isLoggedIn"]);

const toDisplay = computed(() => {
	const routeName = router.currentRoute.value.name;
	if (routeName === 'Profile' || routeName === 'ProfileForUserHashID' || routeName === 'ProfileForUsername' || (routeName === 'CreatorProgramOverview' && isLoggedIn.value)) {
		return false;
	}
	return true;
});

const navDefaultColor = computed(() => {
	const routeName = router.currentRoute.value.name;
	if (routeName === 'FeedForYou' || routeName === 'CreatorProgramOverview') {
		return 'text-white';
	}
	return 'text-black';
});

const navSide = computed(() => {
	const routeName = router.currentRoute.value.name;
	if (routeName === 'CreatorProgramOverview' || routeName === 'Search' || showDashboardNav.value) {
		return 'right';
	}
	return 'left';
});

const showDashboardNav = computed(() => {
	const routeName = router.currentRoute.value.name;
	if (routeName === 'CreatorFAQ' || routeName === 'CreatorDashboard' || routeName === 'CreatorEarnings' || routeName === 'CreatorReviews') {
		return true;
	}

	return false;
});

</script>


<template>
	<div
		class="fixed z-40 top-0 bottom-0 sm:hidden"
		:class="[showLeftSidebar ? (navSide === 'left' ? 'right-0' : 'left-0') : '', navSide === 'left' ? 'left-0' : 'right-0', toDisplay ? 'sm:hidden' : 'hidden']"
	>
		<button
			type="button"
			class="absolute top-6 z-10"
			:class="[navSide === 'left' ? 'left-7' : 'right-7']"
			@click="onNavClick"
		>
			<span
				class="svg-icon w-8 h-8"
				:class="[showLeftSidebar ? (showDashboardNav ? 'text-white' : 'text-black') : navDefaultColor]"
				v-html="featherMenu"
			/>
		</button>

		<div
			v-if="props.showBackdrop"
			class="fixed left-0 right-0 top-0 h-[80px] bg-white"
		>
		</div>

		<div
			v-if="showLeftSidebar"
			class="flex"
		>
			<div
				v-if="showLeftSidebar"
				class="absolute top-0 bottom-0 w-[280px] flex"
				:class="[navSide === 'left' ? 'left-0' : 'right-0', showDashboardNav ? 'px-0 pt-16 bg-gray-950' : 'px-2 pt-20 bg-white']"
			>
				<dashboard-nav
					v-if="showDashboardNav"
					class="relative"
				/>
				<feed-left-sidebar v-else />
			</div>
			<div
				class="bg-black bg-opacity-50 absolute top-0 bottom-0"
				:class="[navSide === 'left' ? 'left-[280px] right-0' : 'right-[280px] left-0']"
				@click="onNavClick"
			/>
		</div>
	</div>
</template>
