import {getField, updateField} from 'vuex-map-fields';
import each from 'lodash/each';
import _ from 'lodash';

const store = {
	namespaced: true,
	state: {
		boosts: {},
	},
	getters: {
		Model: (state) => state.Model,
		getField,
		getBoostsByPayoutID: (state) => (payoutID) => _.filter(state.boosts, { payoutID }),
	},
	mutations: {
		updateField,
		setBoosts(state, boosts) {
			each(boosts, (boost) => {
				state.boosts[boost.boostID] = boost;
			});
		},
	},
};

export default store;
