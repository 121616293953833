import {getField, updateField} from 'vuex-map-fields';
import {size} from 'lodash';
import {request} from '../utils/Request';
import map from "lodash/map";
import {S3_BASE_URL} from "../constants/Review";

export function newProductPageCriteria(parameters = {}, settings = {}) {
	const criteria = {
		// filter criteria
		params: {
			productPageID: null, // use "null" instead of 0 because null will get dropped conveniently
			limit: 10,
			offset: 0,
			includeCount: true,
		},
		settings: {
			setCurrent: true,
			skipLoader: false,
		},
		productPageIDs: [],
		totalCount: 0,
		endOfResults: false,
	};
	Object.assign(criteria.params, parameters);
	Object.assign(criteria.settings, settings);
	return criteria;
}

const store = {
	namespaced: true,
	state: {
		currentCriteria: newProductPageCriteria(),
		productPages: {},
	},
	getters: {
		Model: (state) => state.Model,
		getField,
		// this is not reactive - so I've been using getProducts and then just getting the id on that
		getProductPageByID: (state) => (productPageID) => state.productPages[productPageID] || null,
		currentCriteria: (state) => state.currentCriteria,
		currentProductPages: (state) => map(state.currentCriteria.productPageIDs, (productPageID) => {
			return state.productPages[productPageID];
		}),

		getProductPageThumbnailURL: (state) => (productPage, size = 'sm') => {
			return `${S3_BASE_URL}/${productPage.thumbnailKey}_${size}`;
		},
	},
	mutations: {
		updateField,
		setProductPages(state, productPages) {
			if (productPages) {
				productPages.forEach((productPage) => {
					state.productPages[productPage.productPageID] = productPage;
					state.currentCriteria.productPageIDs.push(productPage.productPageID);
				});
			}
		},
	},
	actions: {
		searchProductPages({ state, commit }, criteria) {
			if (!criteria)
				criteria = newProductPageCriteria();

			if (criteria.settings.setCurrent)
				state.currentCriteria = criteria;

			return new Promise((resolve, reject) => {
				request('get', 'product-page', criteria.params, criteria.settings)
					.then((data) => {
						let length = 0;

						if (criteria.params.includeCount) {
							criteria.totalCount = data.count;
							commit('setProductPages', data.productPages);
							length = size(data.rows);
						} else {
							commit('setProductPages', data.productPages);
							length = size(data);
						}

						criteria.params.offset += length;
						if (criteria.params.offset >= criteria.totalCount)
							criteria.endOfResults = true;

						commit('brandStore/setBrands', data.brands, { root: true });

						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		resetCriteria({ state }) {
			state.currentCriteria = newProductPageCriteria();
		},
	},
};

export default store;
