import {getField, updateField} from 'vuex-map-fields';
import {size, trim, map, each} from 'lodash';
import {S3_BASE_URL} from '../constants/Review';
import {request} from '../utils/Request';
import {hash} from '../utils/Hash';
import {AMAZON_AFFILIATE_LINK} from "../constants/Config";

export function newProductCriteria(parameters = {}, settings = {}) {
	const criteria = {
		// filter criteria
		params: {
			productID: null, // use "null" instead of 0 because null will get dropped conveniently
			limit: 10,
			offset: 0,
			includeCount: true,
		},
		settings: {
			setCurrent: true,
			skipLoader: false,
		},
		productIDs: [],
		totalCount: 0,
		endOfResults: false,
	};
	Object.assign(criteria.params, parameters);
	Object.assign(criteria.settings, settings);
	return criteria;
}

const store = {
	namespaced: true,
	state: {
		currentCriteria: newProductCriteria(),
		products: {},
		productTypes: {},
		productTypeConnectionsByProductID: {},
		productTags: {},
		productTagConnectionsByProductID: {},
	},
	getters: {
		Model: (state) => state.Model,
		getField,
		getProduct: (state, getters) => () => getters.getProductByID(state.productID),
		// this is not reactive - so I've been using getProducts and then just getting the id on that
		getProductByID: (state) => (productID) => state.products[productID] || null,
		getProducts: (state) => () => state.products,
		currentCriteria: (state) => state.currentCriteria,
		currentProducts: (state) => map(state.currentCriteria.productIDs, (productID) => state.products[productID]),
		getProductThumbnailURL: (state) => (product, size = 'sm') => {
			if (!product)
				return '';

			return `${S3_BASE_URL}/${product.thumbnailKey}_${size}`;
		},
		getProductTypes: (state) => (productID) => {
			let connections = state.productTypeConnectionsByProductID[productID];
			if(!connections)
				return [];

			let typesStr = [];
			each(connections, (productTypeConnection, productTypeID) => {
				if (state.productTypes[productTypeID]) {
					typesStr.push(state.productTypes[productTypeID].type);
				}
			});

			return typesStr;
		},
		getProductTags: (state) => (productID) => {
			let connections = state.productTagConnectionsByProductID[productID];
			if(!connections)
				return [];

			let tagsStr = [];
			each(connections, (productTagConnection, productTagID) => {
				if (state.productTags[productTagID]) {
					tagsStr.push(state.productTags[productTagID].tag);
				}
			});

			return tagsStr;
		},
		getProductAffiliateURL: (state) => (product, brand, review) => {
			if (!product && !brand)
				return '';

			// if there's an official affiliate link, use it
			// if (product.affiliateLink)
			// return product.affiliateLink;

			if (review.affiliateLink && review.isAffiliateVerified)
				return review.affiliateLink;

			// otherwise, do a keyword search on Amazon with our affiliate code
			const keywords = encodeURIComponent(trim(`${brand ? brand.name : ''} ${product.name}`));
			return `${AMAZON_AFFILIATE_LINK}${keywords}`;
		},
		getProductPageURL: (state, getters) => (productID) => `/product-page/${hash(productID)}`,
	},
	mutations: {
		updateField,
		setActiveProductID(state, productID) {
			state.productID = productID;
		},
		setProducts(state, products) {
			products.forEach((product) => {
				state.products[product.productID] = product;
				state.currentCriteria.productIDs.push(product.productID);
			});
		},
	},
	actions: {
		getProduct({ commit }, productID) {
			if (!productID)
				return null;

			return new Promise((resolve, reject) => {
				request('get', `product/${hash(productID)}`)
					.then((data) => {
						commit('setProduct', data);
						commit('setActiveProductID', data.productID);
						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		searchProducts({ state, commit }, criteria) {
			if (!criteria)
				criteria = newProductCriteria();

			if (criteria.settings.setCurrent)
				state.currentCriteria = criteria;

			return new Promise((resolve, reject) => {
				request('get', 'product', criteria.params, criteria.settings)
					.then((data) => {
						let length = 0;

						if (criteria.params.includeCount) {
							criteria.totalCount = data.count;
							commit('setProducts', data.rows);
							length = size(data.rows);
						} else {
							commit('setProducts', data);
							length = size(data);
						}

						criteria.params.offset += length;
						if (criteria.params.offset >= criteria.totalCount)
							criteria.endOfResults = true;

						commit('brandStore/setBrands', data.brands, { root: true });

						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		resetCriteria({ state }) {
			state.currentCriteria = newProductCriteria();
		},
	},
};

export default store;
