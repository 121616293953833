import {getField, updateField} from 'vuex-map-fields';
import {
	each, map, size, uniq,
} from 'lodash';
import {request} from '../utils/Request';

export class NotificationActionCriteria {
	constructor(params) {
		this.params = {
			limit: 10,
			offset: 0,
			includeCount: true,
		};
		this.settings = {
			setCurrent: true,
			skipLoader: false,
		};
		this.notificationActionIDs = [];
		this.totalCount = 0;
		this.endOfResults = false;
		Object.assign(this.params, params);
	}
}

const store = {
	namespaced: true,
	state: {
		notificationActions: {},
		currentCriteria: new NotificationActionCriteria(),
	},
	getters: {
		Model: (state) => state.Model,
		getField,
		allNotificationActions: (state) => map(state.currentCriteria.notificationActionIDs, (notificationActionID) => state.notificationActions[notificationActionID]),
	},
	mutations: {
		updateField,
		setNotificationActions(state, notificationActions) {
			each(notificationActions, (notificationAction) => {
				state.currentCriteria.notificationActionIDs.push(notificationAction.notificationActionID);
				state.notificationActions[notificationAction.notificationActionID] = notificationAction;
			});
			state.currentCriteria.notificationActionIDs = uniq(state.currentCriteria.notificationActionIDs);
		},
	},
	actions: {
		getNotificationActions({ state, commit }, criteria) {
			if (!criteria)
				criteria = new NotificationActionCriteria();

			state.currentCriteria = criteria;

			return new Promise((resolve, reject) => {
				request('get', 'notificationAction', criteria.params, criteria.settings)
					.then((data) => {
						let length = 0;

						if (criteria.params.includeCount) {
							criteria.totalCount = data.count;
							commit('setNotificationActions', data.rows);
							length = size(data.rows);
						} else {
							commit('setNotificationActions', data);
							length = size(data);
						}

						criteria.params.offset += length;
						if (criteria.params.offset >= criteria.totalCount)
							criteria.endOfResults = true;

						commit('userStore/setUsers', data.users, { root: true });

						resolve(data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		resetCriteria({ state }) {
			state.currentCriteria = new NotificationActionCriteria();
		},
		markAsSent({ commit }, notificationActionIDs) {
			return new Promise((resolve, reject) => {
				request('post', 'notificationAction/markAsSent', {
					notificationActionIDs,
				})
					.then((notificationActions) => {
						commit('setNotificationActions', notificationActions);
						resolve(notificationActions);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
};

export default store;
