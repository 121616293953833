import {createRouter, createWebHistory} from 'vue-router';

const routes = [
	{
		path: '/',
		name: 'FeedForYou',
		component: () => import(/* webpackChunkName: "feed" */ '../views/Feed.vue'),
	},
	{
		path: '/feed/for-you',
		name: 'OldFeedForYou',
		redirect: '/',
	},
	{
		path: '/feed/following',
		name: 'FeedFollowing',
		component: () => import(/* webpackChunkName: "feed" */ '../views/Feed.vue'),
	},
	{
		path: '/review/edit/:reviewHashID',
		name: 'ReviewEdit',
		component: () => import(/* webpackChunkName: "feed" */ '../views/Feed.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "auth" */ '../views/Login.vue'),
	},
	{
		path: '/login/:required',
		name: 'LoginRequired',
		component: () => import(/* webpackChunkName: "auth" */ '../views/Login.vue'),
	},
	{
		path: '/finish-signup',
		name: 'FinishSignup',
		component: () => import(/* webpackChunkName: "auth" */ '../views/FinishSignup.vue'),
	},
	{
		path: '/check-email',
		name: 'CheckEmail',
		component: () => import(/* webpackChunkName: "auth" */ '../views/CheckEmail.vue'),
	},
	{
		path: '/verify',
		name: 'Verify',
		component: () => import(/* webpackChunkName: "auth" */ '../views/Verify.vue'),
	},
	{
		path: '/search/all/:keywords?',
		name: 'SearchAll',
		component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
	},
	{
		path: '/search',
		name: 'Search',
		component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
	},
	{
		path: '/search/users',
		name: 'SearchUsers',
		component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
	},
	{
		path: '/search/products/:keywords?',
		name: 'SearchProducts',
		component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
	},
	{
		path: '/search/brands/:keywords?',
		name: 'SearchBrands',
		component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
	},
	{
		path: '/creator-program-overview',
		name: 'CreatorProgramOverview',
		component: () => import(/* webpackChunkName: "creator-program-overview" */ '../views/CreatorProgramOverview.vue'),
	},
	// {
	// 	path: '/search/brand/:brandName/products/:keywords?',
	// 	name: 'SearchProductsInBrand',
	// 	component: Search,
	// },
	{
		path: '/restricted',
		name: 'Restricted',
		component: () => import(/* webpackChunkName: "misc" */ '../views/Restricted.vue'),
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
	},
	{
		path: '/giveaway',
		name: 'Giveaway',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/Giveaway.vue'),
	},
	{
		path: '/component-library',
		name: 'Component Library',
		component: () => import(/* webpackChunkName: "component-library" */ '../views/ComponentLibrary.vue'),
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
	},
	{
		path: '/profile/:userHashID',
		name: 'ProfileForUserHashID',
		component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
	},
	{
		path: '/profile/u/:username',
		name: 'ProfileForUsername',
		component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
	},
	{
		path: '/account-settings',
		name: 'AccountSettings',
		component: () => import(/* webpackChunkName: 'account-settings' */ '../views/AccountSettings.vue'),
	},
	{
		path: '/email/unsubscribe/:encodedUsername/:usernameHash/:encodedEmailType',
		name: 'EmailUnsubscribe',
		component: () => import(/* webpackChunkName: 'email-unsubscribe' */ '../views/EmailUnsubscribe.vue'),
	},
	{
		path: '/creator/dashboard',
		name: 'CreatorDashboard',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/CreatorDashboard.vue'),
	},
	{
		path: '/creator/dashboard-welcome',
		name: 'CreatorDashboardWelcome',
		component: () => import(/* webpackChunkName: "dashboard-welcome" */ '../views/CreatorDashboardWelcome.vue'),
	},
	{
		path: '/creator/earnings',
		name: 'CreatorEarnings',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/CreatorEarnings.vue'),
	},
	{
		path: '/creator/join-creator-program',
		name: 'JoinCreatorProgram',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/JoinCreatorProgram.vue'),
	},
	{
		path: '/creator/reviews',
		name: 'CreatorReviews',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/CreatorReviews.vue'),
	},
	{
		path: '/creator/faq',
		name: 'CreatorFAQ',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/CreatorFAQ.vue'),
	},
	{
		path: '/business',
		name: 'Business',
		component: () => import(/* webpackChunkName: "business" */ '../views/Business.vue'),
	},
	{
		path: '/product/:productHashID',
		name: 'Product',
		component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue'),
	},
	{
		path: '/product-page/:productPageHashID',
		name: 'ProductPage',
		component: () => import(/* webpackChunkName: "product-page" */ '../views/ProductPage.vue'),
	},
	{
		path: '/brand-page/:brandHashID',
		name: 'BrandPage',
		component: () => import(/* webpackChunkName: "brand-page" */ '../views/BrandPage.vue'),
	},
	{
		path: '/review/:reviewHashID',
		name: 'Review',
		component: () => import(/* webpackChunkName: "review" */ '../views/Review.vue'),
	},
	{
		path: '/review/:reviewHashID/comment/:commentHashID',
		name: 'ReviewWithComment',
		component: () => import(/* webpackChunkName: "review" */ '../views/Review.vue'),
	},
	{
		path: '/admin',
		name: 'Admin',
		component: () => import(/* webpackChunkName: 'admin' */ '../views/Admin.vue'),
	},
	{
		path: '/newsroom',
		name: 'Newsroom',
		component: () => import(/* webpackChunkName: 'newsroom' */ '../views/Newsroom.vue'),
	},
	{
		path: '/newsroom/revvy-now-live',
		name: 'RevvyNowLive',
		component: () => import(/* webpackChunkName: 'revvy-now-live' */ '../views/newsroom/RevvyNowLive.vue'),
	},
	{
		path: '/newsroom/what-is-a-great-review',
		name: 'WhatIsAGreatReview',
		component: () => import(/* webpackChunkName: 'what-is-a-great-review' */ '../views/newsroom/WhatIsAGreatReview.vue'),
	},
	{
		path: '/newsroom/earn-with-honesty-on-revvy',
		name: 'EarnWithHonestyOnRevvy',
		component: () => import(/* webpackChunkName: 'earn-with-honesty-on-revvy' */ '../views/newsroom/EarnWithHonestyOnRevvy.vue'),
	},
	{
		path: '/newsroom/make-money-writing-reviews',
		name: 'EarnWithHonesty',
		component: () => import(/* webpackChunkName: 'make-money-writing-reviews' */ '../views/newsroom/MakeMoneyWritingReviews.vue'),
	},
	{
		path: '/newsroom/how-to-spot-fake-reviews',
		name: 'HowToSpotFakeReviews',
		component: () => import(/* webpackChunkName: 'how-to-spot-fake-reviews' */ '../views/newsroom/HowToSpotFakeReviews.vue'),
	},
	{
		path: '/newsroom/how-revvy-keeps-it-honest',
		name: 'HowRevvyKeepsItHonest',
		component: () => import(/* webpackChunkName: 'how-revvy-keeps-it-honest' */ '../views/newsroom/HowRevvyKeepsItHonest.vue'),
	},
	{
		path: '/newsroom/are-product-reviews-reliable',
		name: 'AreProductReviewsReliable',
		component: () => import(/* webpackChunkName: 'are-product-reviews-reliable' */ '../views/newsroom/AreProductReviewsReliable.vue'),
	},
	{
		path: '/newsroom/are-product-review-jobs-legit',
		name: 'AreProductReviewJobsLegit',
		component: () => import(/* webpackChunkName: 'are-product-review-jobs-legit' */ '../views/newsroom/AreProductReviewJobsLegit.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
